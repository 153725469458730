import React, { useState } from "react";
import { ReactComponent as InfoIcon } from "sign-up/superpay/assets/info.svg";
import { ReactComponent as CopyIcon } from 'assets/copy.svg';
import Loader from "components/Loader";
import { DateTime } from "luxon";
import { ReactComponent as EyeIcon } from "assets/eye-icon.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import KeyValueGrid from "./key-value-grid";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@material-tailwind/react";
import { ReactComponent as ForwardIcon } from "assets/pagination-forward-arrow.svg";
import { ReactComponent as BackIcon } from "assets/pagination-backward-arrow.svg";
import { paginationHelper, PaginationStates } from "utils/pagination";
import { QMBatchDataType } from 'types/types';


type BatchesTableProps = {
  dataLoading: boolean;
  batches: QMBatchDataType[] | null;
  batchData?: any;
  onExpand: (robodialerId: string, batchId: string, force?: boolean) => void;
};
export default function BatchesTable({
  dataLoading,
  batches,
  batchData,
  onExpand = () => {},
}: BatchesTableProps) {

  const navigate = useNavigate();

  const [expanded, setExpanded] = useState<string | false>(false);
  const [selectedKey, setSelectedKey] = useState<string | number | null>(null);

  const onCopy = (content: string | number) => {
      navigator.clipboard.writeText(content.toString());
      toast.success('Copied to clipboard', {
        iconTheme: {
          primary: '#4a43cd',
          secondary: '#ffffff',
        },
      }
      );
    }

  const [currentResultsPageIndex, setCurrentResultsPageIndex] =
    useState<number>(0);
  const {
    newEntries: currentActiveResults,
    currentLowerIndex,
    currentUpperIndex,
    changePagination,
  } = paginationHelper({
    entries: batches ?? [],
    currentPageIndex: currentResultsPageIndex,
    maxEntriesPerPage: 25,
    setCurrentPageIndex: setCurrentResultsPageIndex,
  });
  return (
    <>
      {(currentActiveResults ?? []).map((batch, index) => (
        <Accordion
          key={`${batch?.id ?? batch?.batchId}-${index}`}
          expanded={expanded === `panel-${index}`}
          className={`m-0 bg-white border-b border-superpay-soap`}
        >
          <AccordionSummary
          className="!p-0"
            expandIcon={
                <ExpandMoreIcon onClick={() => {
                  let panel = `panel-${index}`;
                  let robodialerId = batch?.robodialerId;
                  let batchId = batch?.batchId;
                  console.log('ExpandMoreIcon');
                  console.log(panel, robodialerId, batchId);
                  setExpanded(expanded !== panel ? panel : false)
                  onExpand(robodialerId, batchId);
                  /* handleChange(
                    `panel-${index}`,
                    batch?.robodialerId,
                    batch?.batchId
                  ) */
              }}/>
          }
            aria-controls={`panel-${index}-content`}
            id={`panel-${index}-header`}
          >
            <div className="w-full grid grid-cols-24">
              <div
                className={`flex items-center justify-center col-span-1 py-1 bg-white`}
              >
                {batch?.cancelled || batch?.hide ? <>
                  <>
                    <Tooltip
                      content={
                        <div className="gap-2">
                          <div style={{ whiteSpace: "pre-line" }}>
                            Cancelled:{batch?.cancelled ? "Yes" : "No"}
                          </div>
                          <div style={{ whiteSpace: "pre-line" }}>
                            Paused:{batch?.hide ? "Yes" : "No"}
                        </div>
                        </div>
                      }
                      className="max-w-[200px] bg-black py-1 px-2"
                    >
                        <div className="flex items-center gap-x-1">
                          <div className={`cursor-pointer px-2`}>
                            <InfoIcon className='min-w-[22px] child:fill-superbill-jacarta' />
                          </div>
                        </div>
                    </Tooltip>
                  </>
                </> : null}
              </div>
              <div
                className={`flex items-center justify-start col-span-2 py-1 bg-white`}
              >
                {
                  <div className="p-2 flex items-center justify-center text-center font-bold text-sm text-superbill-ultra-red bg-superbill-banner-red rounded-full">
                    {DateTime.fromJSDate(new Date(batch?.dueDate?.et)).toFormat(
                      "MMMM dd HH:mm"
                    )}
                  </div>
                }
              </div>
              <div
                className={`${
                  batch?.status === "completed" ? "font-bold" : "italic"
                } flex items-center justify-start col-span-2 py-1 bg-white px-2`}
              >
                <span className="text-sm text-superbill-jacarta font-jakarta flex items-center">
                  {batch?.status}
                </span>
              </div>
              <div className="flex items-center justify-start col-span-3 py-1 bg-white px-2">
                <span className="text-sm text-superbill-jacarta font-jakarta flex items-center">
                <Tooltip
                  content={
                    <span style={{ whiteSpace: "pre-line" }}>
                      {batch?.robodialerId}
                    </span>
                  }
                  className="max-w-[200px] bg-black py-1 px-2"
                >
                  <div className="flex items-center gap-x-1">
                    <div className={`cursor-pointer px-2`} onClick={() => onCopy(batch?.robodialerId)}>
                      <CopyIcon/>
                    </div>
                    {batch?.robodialerName ?? batch?.robodialerId}
                  </div>
                </Tooltip>
                </span>
              </div>
              <div className="flex items-center justify-start col-span-4 py-1 bg-white px-2">
                <span className="text-sm text-superbill-jacarta font-jakarta flex items-center">
                  <div className={`cursor-pointer px-2`} onClick={() => onCopy(batch?.batchId)}>
                    <CopyIcon/>
                  </div>
                  {batch?.batchId}
                </span>
              </div>
              <div className="flex items-center justify-start col-span-4 py-1 bg-white px-2">
                <span className="text-sm text-superbill-jacarta font-jakarta flex items-center">
                  <div className={`cursor-pointer px-2`} onClick={() => onCopy(batch?.scriptId)}>
                    <CopyIcon/>
                  </div>
                  {batch?.scriptId}
                </span>
              </div>
              <div
                className={`col-span-2 flex items-center justify-end py-1 bg-white px-2`}
              >
                {
                  <div className="p-2 h-fit flex items-center justify-end text-right font-bold text-sm text-superbill-indigo bg-superbill-lavender rounded-full">
                    {(typeof batch?.numSuccessful === "string"
                      ? parseInt(batch?.numSuccessful)
                      : batch?.numSuccessful) || 0}
                  </div>
                }
              </div>
              <div
                className={`col-span-2 flex items-center justify-end py-1 bg-white px-2`}
              >
                {
                  <div className="p-2 h-fit flex items-center justify-end text-right font-bold text-sm text-superbill-ultra-red bg-superbill-banner-red rounded-full">
                    {(typeof batch?.numFailed === "string"
                      ? parseInt(batch?.numFailed)
                      : batch?.numFailed) || 0}
                  </div>
                }
              </div>
              <div className="col-span-2 flex items-center justify-end py-1 bg-white px-2">
                <div className="text-sm flex items-center justify-end text-superbill-jacarta text-right font-jakarta">
                  {batch?.numCalls -
                    (((typeof batch?.numSuccessful === "string"
                      ? parseInt(batch?.numSuccessful)
                      : batch?.numSuccessful) || 0) +
                      ((typeof batch?.numFailed === "string"
                        ? parseInt(batch?.numFailed)
                        : batch?.numFailed) || 0))}
                </div>
              </div>
              <div className="col-span-2 flex items-center justify-end py-1 bg-white px-2">
                <div className="text-sm flex items-center justify-end text-superbill-jacarta text-right font-jakarta">
                  {batch?.numCalls}
                </div>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="flex items-center p-2 bg-superbill-lavender-medium rounded mb-2 justify-between">
              <div className="text-left text-superbill-mangosteen text-sm">
                <strong>PATH:</strong> Robodialers/{batch?.robodialerId}/batches/
                {batch?.batchId}
              </div>
              <div className="cursor-pointer hover-underline-animation text-sm w-fit" onClick={() => {
                //navigate() to /calls passing parameters as query
                navigate(`/agents-dashboard/tools/calls?robodialerId=${batch?.robodialerId}&batchId=${batch?.batchId}`)
              }}>All calls</div>
            </div>
            {dataLoading ? (
              <>
                <div className="w-full h-full flex justify-center items-center">
                  {" "}
                  <Loader />{" "}
                </div>
              </>
            ) : null}
            {!dataLoading ? (  
              <div className="w-full gap-x-4 grid grid-cols-10 justify-start items-start">
                <div className=" col-span-3 border border-superpay-soap divide-y divide-superpay-soap rounded">
                  <div className="grid grid-cols-10 gap-4 bg-superbill-anti-flash-white-darken">
                    <span className="col-span-3 px-2 text-sm py-1 text-superbill-jacarta font-jacarta font-bold">
                      Status:
                    </span>
                    <span className="col-span-7 px-2 text-sm py-1 text-superbill-jacarta font-jacarta text-right">
                      Total
                    </span>
                  </div>
                  <KeyValueGrid
                    aspect="3-7"
                    icon={<span className="cursor-pointer">{">"}</span>}
                    action={(e, a) => {
                      setSelectedKey(a || null);
                    }}
                    data={{
                      failure_data:
                        batchData && batchData[batch?.batchId]
                          ? batchData[batch?.batchId].failure_data?.length
                          : 0,
                      retry_data:
                        batchData && batchData[batch?.batchId]
                          ? batchData[batch?.batchId].retry_data?.length
                          : 0,
                      robo_unconfirmed_data:
                        batchData && batchData[batch?.batchId]
                          ? batchData[batch?.batchId].robo_unconfirmed_data?.length
                          : 0,
                      success_data:
                        batchData && batchData[batch?.batchId]
                          ? batchData[batch?.batchId].success_data?.length
                          : 0,
                      unclassified_data:
                        batchData && batchData[batch?.batchId]
                          ? batchData[batch?.batchId].unclassified_data?.length
                          : 0,
                    }}
                  />
                </div>
                <div className="col-span-7 w-full">
                  {selectedKey &&
                  batchData[batch?.batchId] &&
                  batchData[batch?.batchId][selectedKey] ? (
                    <div className="max-h-[250px]  overflow-y-auto">
                      <div className="w-full border border-superpay-soap divide-y divide-superpay-soap rounded">
                        <div className="grid grid-cols-10 gap-4 bg-superbill-anti-flash-white-darken">
                          <span className="col-span-3 px-2 text-sm py-1 text-superbill-jacarta font-jacarta font-bold">
                            CallId
                          </span>
                          <span className="col-span-3 px-2 text-sm py-1 text-superbill-jacarta font-jacarta text-right">
                            Outcome
                          </span>
                          <span className="col-span-3 px-2 text-sm py-1 text-superbill-jacarta font-jacarta text-right">
                            Error
                          </span>
                          <span className="col-span-1 px-2 text-sm py-1 text-superbill-jacarta font-jacarta text-right">
                          </span>
                        </div>
                        {batchData[batch?.batchId][selectedKey].map(
                          (obj, index) => (
                            <div
                              key={`${selectedKey}-${index}`}
                              className="grid grid-cols-10 gap-4 bg-white"
                            >
                              <span className="col-span-3 px-2 text-sm py-1 text-superbill-jacarta font-jacarta font-bold">
                                {obj["Call ID"]}
                              </span>
                              <span className="col-span-3 px-2 text-sm py-1 text-superbill-jacarta font-jacarta text-right">
                                {obj["Outcome"] ?? selectedKey}
                              </span>
                              <span className="col-span-3 px-2 text-sm py-1 text-superbill-jacarta font-jacarta text-right">
                                {obj["Error"] ?? "-"}
                              </span>
                              <span className="col-span-1 px-2 text-sm py-1 text-superbill-jacarta font-jacarta text-right" onClick={() => {
                                navigate(`/agents-dashboard/tools/calls?robodialerId=${batch?.robodialerId}&batchId=${batch?.batchId}&callId=${obj["Call ID"]}`)
                              }}>
                                <div className="flex items-center justify-end"><EyeIcon className="h-[14px] cursor-pointer"/></div>
                              </span>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}
          </AccordionDetails>
        </Accordion>
      ))}
      <div className="flex items-center justify-end flex-row py-3 px-2 font-sans">
        <div
          className="cursor-pointer fill-superbill-gray-2 hover:fill-superbill-ultramarine"
          onClick={() => changePagination(PaginationStates.backward)}
        >
          {currentLowerIndex === 0 ? <></> : <BackIcon />}
        </div>
        <div className="mx-2">
          <strong>
            {currentLowerIndex + 1} to {currentUpperIndex + 1}
          </strong>{" "}
          of {batches?.length}
        </div>
        <div
          className="cursor-pointer fill-superbill-gray-2 hover:fill-superbill-ultramarine"
          onClick={() => changePagination(PaginationStates.forward)}
        >
          {currentUpperIndex + 1 === batches?.length ? <></> : <ForwardIcon />}
        </div>
      </div>
    </>
  );
}
