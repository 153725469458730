import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as ResponseIcon } from 'assets/icon_responseInstructions.svg';
import { Tooltip } from '@material-tailwind/react';
import Select from 'react-select';
import { ReactComponent as BackgroundIcon } from 'assets/icon_backgroundInfo.svg';
interface Question {
  backgroundContext?: string;
  responseInstruction?: string;

  answerOptions?: string[]
  answerType: string;
  question: string;
  questionId: string;
  parentAnswer?: boolean;
  questions: Question[];
}

interface Option {
  value: boolean | '';
  label: string;
}

const FormComponent: React.FC<{
  callInputs: Record<string, string>,
  questions: Question[], selectedOptions,
  setSelectedOptions, 
}> = ({ questions, selectedOptions, setSelectedOptions, callInputs }) => {
  
  const removeObjectPropertiesWithPrefix = (prefix: string, obj: Record<string, any>): Record<string, any> => {
    const newObj: Record<string, any> = {};

    for (const key in obj) {
      if (key.startsWith(prefix+'.')) {
        continue; // ignore same key
      }

      if (typeof obj[key] === "object" && obj[key] !== null) {

        newObj[key] = removeObjectPropertiesWithPrefix(prefix, obj[key]);
      } else {
        // Copy
        newObj[key] = obj[key];
      }
    }
    return newObj;
  };

  const handleChange = (questionId: string, selectedOption: Option | string | null, removeChilds = true) => {
    let updatedSelectedOptions = removeChilds ? removeObjectPropertiesWithPrefix(questionId, { ...selectedOptions }) : { ...selectedOptions }
    updatedSelectedOptions[questionId] = selectedOption;
    setSelectedOptions(updatedSelectedOptions);
  };
  

  const formatDateString = (inputValue = '') => {
    if (typeof inputValue !== 'string') {
      return inputValue ?? ''
    }
    inputValue = inputValue ? inputValue?.replace(/[^0-9-]/g, '') : ''
    
    const trimmedValue = inputValue?.replace(/-/g, '');
    if (trimmedValue?.length <= 4) {
      return trimmedValue;
    } else if (trimmedValue?.length <= 6) {
      return `${trimmedValue?.slice(0, 4)}-${trimmedValue?.slice(4)}`;
    } else {
      return `${trimmedValue?.slice(0, 4)}-${trimmedValue?.slice(4, 6)}-${trimmedValue?.slice(6, 8)}`;
    }
  };

  const formatString = (template: string): string => {
    return template?.replace(/\{([^}]+)\}/g, (match, key) => {
      const value = callInputs[key.trim()];
      if (!value) {
        throw new Error(`Key ${key} not found in call inputs`);
      }
      return value
    });
  }

  const renderQuestion = (questionObj: Question, index = 0) => {
    const { questionId, question, answerType, questions, answerOptions, backgroundContext, responseInstruction } = questionObj;
    const hasChildren = questions && questions?.length > 0;
    let formattedQuestion = ''
    try {
      formattedQuestion = formatString(question);
    } catch (error) {
      // hide this q
      return
    }
    if (!selectedOptions.hasOwnProperty(questionId)) {
      setSelectedOptions(prevState => ({
        ...prevState,
        [questionId]: ''
      }));
    }

    let boolOptions = [
        { value: true, label: 'Yes' },
        { value: false, label: 'No' },
        { value: '', label: 'Select...' },
        { value: "CANNOT ANSWER - Representative does not know the answer", label: "CANNOT ANSWER - Representative does not know the answer" },
        { value: "CANNOT ANSWER - Wrong number for this question", label: "CANNOT ANSWER - Wrong number for this question" },
        { value: "CANNOT ANSWER - Representative unable to answer with info", label: "CANNOT ANSWER - Representative unable to answer with info" },
        { value: "CANNOT ANSWER - other", label: "CANNOT ANSWER - other" },
      ]
    const standardCannotAnswers = [
        "CANNOT ANSWER - Representative does not know the answer",
        "CANNOT ANSWER - Wrong number for this question",
        "CANNOT ANSWER - Representative unable to answer with info"
    ];
    // if the question is a custom cannot answer, set other_reason attribute
    if (answerType === "boolean") {
        let selectedOption = boolOptions.find(
            (option) => option.value === selectedOptions[questionId] 
            || 
            option.label === selectedOptions[questionId]
        );
        const value = selectedOptions[questionId];
        const stringValue = (typeof value !== 'object' ? value : (value?.value ?? ""))
        if (typeof selectedOption === 'undefined' && typeof stringValue === 'string' && stringValue != '' && stringValue.startsWith("CANNOT ANSWER - ") && !standardCannotAnswers.includes(stringValue)) {
          let customReason = stringValue.replace("CANNOT ANSWER - ", "").trim().toLowerCase();
          if(customReason === "other") {
            customReason = ""
          }
          selectedOptions[questionId] = { value: "CANNOT ANSWER - other", label: "CANNOT ANSWER - other", other_reason: customReason};
        }
    }

    let p = index + 10
    switch (answerType) {
      case 'boolean':

        const getSelectedOption = (questionId) => {
          const value = selectedOptions[questionId];
          const stringValue = (typeof value !== 'object' ? value : (value?.value ?? ""))
          let selectedOption= boolOptions.find(option => {
            return option.value === stringValue || option.label === stringValue
          })

          if (typeof selectedOption === 'undefined' && typeof stringValue === 'string' && stringValue != '' && stringValue.startsWith("CANNOT ANSWER - ") && !standardCannotAnswers.includes(stringValue)) {
            let customReason = stringValue?.replace("CANNOT ANSWER - ", "").trim();
            return { value: "CANNOT ANSWER - other", label: "CANNOT ANSWER - other", other_reason: customReason}
          }
          return selectedOption;
        };
        
        const shouldShowOtherInput = (questionId) => {
            const value = selectedOptions[questionId];
            const stringValue = (typeof value !== 'object' ? value : (value?.value ?? ""))
            return typeof stringValue === 'string' && stringValue != '' && stringValue.startsWith("CANNOT ANSWER - ") && !standardCannotAnswers.includes(stringValue);
        };

        return (<div style={{ marginLeft: 1.5*index, marginTop: '4px' }} key={'div_' + questionId}>
          <div className='flex items-center justify-between w-full'><div className='mt-1 mb-0.5 text-sm text-superbill-jacarta'>{formattedQuestion}</div><div className='mt-1 mb-0.5 text-xs text-superbill-jacarta'>Id:{questionId}</div></div>
          {backgroundContext ? 
            <div className="bg-superbill-indigo/10 text-xs text-superbill-jacarta flex items-ceneter py-1 rounded-tr hover:text-superbill-ultramarine hover:cursor-pointer"> 
            <Tooltip
              content={
                <span style={{ whiteSpace: 'pre-line' }}>
                  <span className="text-sm">Background information can help you communicate with the representative.</span>
                </span>
              } className='bg-black py-1 px-2'
            >
              <BackgroundIcon className='w-[14px] fill-superbill-jacarta mx-1'/>
            </Tooltip>
              <span>
                {backgroundContext}
              </span>
            </div>
          : null}
          <Select
            value={getSelectedOption(questionId)}
            onChange={(selectedOption) => handleChange(questionId, selectedOption as Option)}
            options={boolOptions}
          />
          {responseInstruction? 
              <div className="mt-1 text-xs text-superbill-slate-grey flex items-ceneter py-1 mb-3 hover:text-superbill-ultramarine hover:cursor-pointer"> 
              <Tooltip
                content={
                  <span style={{ whiteSpace: 'pre-line' }}>
                    <span className="text-sm">Response instructions will help you capture information in the desired way.</span>
                  </span>
                } className='bg-black py-1 px-2'
              >
                <ResponseIcon className='w-[14px] fill-superbill-slate-grey mx-1'/>
              </Tooltip>
                <span>
                  {responseInstruction}
                </span>
              </div>
              : null}
          {hasChildren && (selectedOptions[questionId] === 'Yes' || selectedOptions[questionId] === true || selectedOptions[questionId]?.value === true) && renderQuestions((questions || [])?.filter(q => q.parentAnswer === true), p)}
          {hasChildren && (selectedOptions[questionId] === 'No' || selectedOptions[questionId] === false || selectedOptions[questionId]?.value === false) && renderQuestions((questions || [])?.filter(q => q.parentAnswer === false), p)}
          {shouldShowOtherInput(questionId) ?
            <div style={{ marginLeft: 1.5*(index + 10), marginTop: '4px' }} key={'div_' + questionId}>
            <div className='flex items-center justify-between w-full'><div className='mt-1 mb-0.5 text-sm text-superbill-jacarta'>Please explain why no answer could be given.</div></div>
            <input
              key={'input_' + questionId}
              name={'name_' + questionId}
              type="text"
              placeholder="Enter a question"
              value={selectedOptions[questionId].other_reason ?? ""}
              className={`w-full rounded border-superbill-soap focus:border-superbill-indigo focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-base`}
              onChange={(e) => {
                const inputValue = e.target.value?.replace(/[^a-zA-Z0-9\s\'\.\,\-\$\@\:]+$/, "")
                e.target.value = inputValue;
                handleChange(questionId, `CANNOT ANSWER - ${inputValue}`, false)
              }}
            />
            <div className='mt-1 mb-0.5 text-xs text-superbill-ultra-red'>{/^[a-zA-Z0-9\s\.\:\,\-\$\@\']+$/.test(selectedOptions[questionId].other_reason) ? '' : 'Invalid input. Only letters, numbers, spaces, and the characters \'-:.,@$. are allowed'}</div>
          </div>
            : null}
        </div>
        )
      case 'date':
        return (
          <div style={{ marginLeft: 1.5*index, marginTop: '4px' }} key={'div_' + questionId}>
            <div className='flex items-center justify-between w-full'><div className='mt-1 mb-0.5 text-sm text-superbill-jacarta'>{formattedQuestion}</div><div className='mt-1 mb-0.5 text-xs text-superbill-jacarta'>Id:{questionId}</div></div>
            {backgroundContext ? 
              <div className="bg-superbill-indigo/10 text-xs text-superbill-jacarta flex items-ceneter py-1 rounded-tr hover:text-superbill-ultramarine hover:cursor-pointer"> 
              <Tooltip
                content={
                  <span style={{ whiteSpace: 'pre-line' }}>
                    <span className="text-sm">Background information can help you communicate with the representative.</span>
                  </span>
                } className='bg-black py-1 px-2'
              >
                <BackgroundIcon className='w-[14px] fill-superbill-jacarta mx-1'/>
              </Tooltip>
                <span>
                  {backgroundContext}
                </span>
              </div>
            : null}
            <input
              key={'input_' + questionId}
              name={'name_' + questionId}
              type="text"
              placeholder="Format: yyyy-mm-dd"
              disabled={selectedOptions[questionId] === 'CANNOT ANSWER - Representative does not know the answer'}
              value={selectedOptions[questionId] !== 'CANNOT ANSWER - Representative does not know the answer' ? formatDateString(selectedOptions[questionId]) : 'CANNOT ANSWER - Representative does not know the answer'}
              className={`w-full rounded border-superbill-soap focus:border-superbill-indigo focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-base`}
              onChange={(e) => {
                handleChange(questionId, formatDateString(e.target.value))
              }}
            />
            <div className='mb-0.5'>
              <button type='button' className='w-fit hover-underline-animation cursor-pointer text-xs' onClick={() => {
                if (selectedOptions[questionId] === 'CANNOT ANSWER - Representative does not know the answer') {
                  handleChange(questionId, '')
                } else {
                  handleChange(questionId, 'CANNOT ANSWER - Representative does not know the answer')
                }
              }}>{selectedOptions[questionId] === 'CANNOT ANSWER - Representative does not know the answer' ? "Edit Date" : "Select this option if the date is unknown"}</button>
            </div>
            {responseInstruction? 
              <div className="mt-1 text-xs text-superbill-slate-grey flex items-ceneter py-1 mb-3 hover:text-superbill-ultramarine hover:cursor-pointer"> 
              <Tooltip
                content={
                  <span style={{ whiteSpace: 'pre-line' }}>
                    <span className="text-sm">Response instructions will help you capture information in the desired way.</span>
                  </span>
                } className='bg-black py-1 px-2'
              >
                <ResponseIcon className='w-[14px] fill-superbill-slate-grey mx-1'/>
              </Tooltip>
                <span>
                  {responseInstruction}
                </span>
              </div>
              : null}
          </div>
        )
        break
      case 'number':
        return (
          <div style={{ marginLeft: 1.5*index, marginTop: '4px' }} key={'div_' + questionId}>
            <div className='flex items-center justify-between w-full'><div className='mt-1 mb-0.5 text-sm text-superbill-jacarta'>{formattedQuestion}</div><div className='mt-1 mb-0.5 text-xs text-superbill-jacarta'>Id:{questionId}</div></div>
            {backgroundContext ? 
              <div className="bg-superbill-indigo/10 text-xs text-superbill-jacarta flex items-ceneter py-1 rounded-tr hover:text-superbill-ultramarine hover:cursor-pointer"> 
              <Tooltip
                content={
                  <span style={{ whiteSpace: 'pre-line' }}>
                    <span className="text-sm">Background information can help you communicate with the representative.</span>
                  </span>
                } className='bg-black py-1 px-2'
              >
                <BackgroundIcon className='w-[14px] fill-superbill-jacarta mx-1'/>
              </Tooltip>
                <span>
                  {backgroundContext}
                </span>
              </div>
            : null}
            <input
              key={'input_' + questionId}
              name={'name_' + questionId}
              type="text"
              placeholder="Enter a number"
              value={selectedOptions[questionId]}
              className={`w-full rounded border-superbill-soap focus:border-superbill-indigo focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-base`}
              onChange={(e) => {
                const inputValue = e.target.value?.replace(/[^0-9]/g, "")
                e.target.value = inputValue;
                handleChange(questionId, inputValue)

              }}
            />
            {responseInstruction? 
              <div className="mt-1 text-xs text-superbill-slate-grey flex items-ceneter py-1 mb-3 hover:text-superbill-ultramarine hover:cursor-pointer"> 
              <Tooltip
                content={
                  <span style={{ whiteSpace: 'pre-line' }}>
                    <span className="text-sm">Response instructions will help you capture information in the desired way.</span>
                  </span>
                } className='bg-black py-1 px-2'
              >
                <ResponseIcon className='w-[14px] fill-superbill-slate-grey mx-1'/>
              </Tooltip>
                <span>
                  {responseInstruction}
                </span>
              </div>
              : null}
          </div>
        )
        break
      case 'dollar':
        return (
          <div style={{ marginLeft: 1.5*index, marginTop: '4px' }} key={'div_' + questionId}>
            <div className='flex items-center justify-between w-full'><div className='mt-1 mb-0.5 text-sm text-superbill-jacarta'>{formattedQuestion}</div><div className='mt-1 mb-0.5 text-xs text-superbill-jacarta'>Id:{questionId}</div></div>
            {backgroundContext ? 
              <div className="bg-superbill-indigo/10 text-xs text-superbill-jacarta flex items-ceneter py-1 rounded-tr hover:text-superbill-ultramarine hover:cursor-pointer"> 
              <Tooltip
                content={
                  <span style={{ whiteSpace: 'pre-line' }}>
                    <span className="text-sm">Background information can help you communicate with the representative.</span>
                  </span>
                } className='bg-black py-1 px-2'
              >
                <BackgroundIcon className='w-[14px] fill-superbill-jacarta mx-1'/>
              </Tooltip>
                <span>
                  {backgroundContext}
                </span>
              </div>
            : null}
            <div className='flex items-center justify-center gap-x-1'>
              <strong>$</strong>
              <input
                key={'input_' + questionId}
                name={'name_' + questionId}
                type="number"
                placeholder="Amount"
                value={selectedOptions[questionId]}
                className={`w-full rounded border-superbill-soap focus:border-superbill-indigo focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-base`}
                onChange={(e) => {
                  let formattedValue = e.target.value;
                  formattedValue = formattedValue?.replace(/[^0-9.]/g, '');
                  let parts = formattedValue.split('.');
                  if (parts?.length > 2) {
                    formattedValue = parts[0] + '.' + parts?.slice(1).join('');
                  }
                  if (parts?.length > 1) {
                    parts[1] = parts[1].substring(0, 2);
                    formattedValue = parts[0] + '.' + parts[1];
                  }
                  e.target.value = formattedValue;
                  handleChange(questionId, formattedValue)
                }}
                onBlur={(e) => {
                  let formattedValue = e.target.value;
                  if (!formattedValue.includes('.')) {
                    formattedValue += '.00';
                  }
                }}
              />
            </div>
            {responseInstruction? 
              <div className="mt-1 text-xs text-superbill-slate-grey flex items-ceneter py-1 mb-3 hover:text-superbill-ultramarine hover:cursor-pointer"> 
              <Tooltip
                content={
                  <span style={{ whiteSpace: 'pre-line' }}>
                    <span className="text-sm">Response instructions will help you capture information in the desired way.</span>
                  </span>
                } className='bg-black py-1 px-2'
              >
                <ResponseIcon className='w-[14px] fill-superbill-slate-grey mx-1'/>
              </Tooltip>
                <span>
                  {responseInstruction}
                </span>
              </div>
              : null}
          </div>
        )

        break
      case 'alphanumeric':
        return (
          <div style={{ marginLeft: 1.5*index, marginTop: '4px' }} key={'div_' + questionId}>
            <div className='flex items-center justify-between w-full'><div className='mt-1 mb-0.5 text-sm text-superbill-jacarta'>{formattedQuestion}</div><div className='mt-1 mb-0.5 text-xs text-superbill-jacarta'>Id:{questionId}</div></div>
            {backgroundContext ? 
              <div className="bg-superbill-indigo/10 text-xs text-superbill-jacarta flex items-ceneter py-1 rounded-tr hover:text-superbill-ultramarine hover:cursor-pointer"> 
              <Tooltip
                content={
                  <span style={{ whiteSpace: 'pre-line' }}>
                    <span className="text-sm">Background information can help you communicate with the representative.</span>
                  </span>
                } className='bg-black py-1 px-2'
              >
                <BackgroundIcon className='w-[14px] fill-superbill-jacarta mx-1'/>
              </Tooltip>
                <span>
                  {backgroundContext}
                </span>
              </div>
            : null}
            <input
              key={'input_' + questionId}
              name={'name_' + questionId}
              type="text"
              placeholder="Enter a number"
              value={selectedOptions[questionId]}
              className={`w-full rounded border-superbill-soap focus:border-superbill-indigo focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-base`}
              onChange={(e) => {
                const inputValue = e.target.value?.replace(/[^a-zA-Z0-9]/g, "")
                e.target.value = inputValue;
                handleChange(questionId, inputValue)
              }}
            />
            {responseInstruction? 
              <div className="mt-1 text-xs text-superbill-slate-grey flex items-ceneter py-1 mb-3 hover:text-superbill-ultramarine hover:cursor-pointer"> 
              <Tooltip
                content={
                  <span style={{ whiteSpace: 'pre-line' }}>
                    <span className="text-sm">Response instructions will help you capture information in the desired way.</span>
                  </span>
                } className='bg-black py-1 px-2'
              >
                <ResponseIcon className='w-[14px] fill-superbill-slate-grey mx-1'/>
              </Tooltip>
                <span>
                  {responseInstruction}
                </span>
              </div>
              : null}
          </div>
        )
        break
      case 'phoneNumber':
        return (<div style={{ marginLeft: 1.5*index, marginTop: '4px' }} key={'div_' + questionId}>
          <div className='flex items-center justify-between w-full'><div className='mt-1 mb-0.5 text-sm text-superbill-jacarta'>{formattedQuestion}</div><div className='mt-1 mb-0.5 text-xs text-superbill-jacarta'>Id:{questionId}</div></div>
          {backgroundContext ? 
              <div className="bg-superbill-indigo/10 text-xs text-superbill-jacarta flex items-ceneter py-1 rounded-tr hover:text-superbill-ultramarine hover:cursor-pointer"> 
              <Tooltip
                content={
                  <span style={{ whiteSpace: 'pre-line' }}>
                    <span className="text-sm">Background information can help you communicate with the representative.</span>
                  </span>
                } className='bg-black py-1 px-2'
              >
                <BackgroundIcon className='w-[14px] fill-superbill-jacarta mx-1'/>
              </Tooltip>
                <span>
                  {backgroundContext}
                </span>
              </div>
            : null}
          <input
            key={'input_' + questionId}
            name={'name_' + questionId}
            type="text"
            placeholder="Use this format: 123-456-7890"
            value={selectedOptions[questionId]}
            className={`w-full rounded border-superbill-soap focus:border-superbill-indigo focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-base`}
            onChange={(e) => {
              const inputValue = e.target.value
              let formattedValue = inputValue?.replace(/[^\d-]/g, '')?.slice(0, 12)?.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
              e.target.value = formattedValue
              handleChange(questionId, formattedValue)
            }}
          />
          {responseInstruction? 
              <div className="mt-1 text-xs text-superbill-slate-grey flex items-ceneter py-1 mb-3 hover:text-superbill-ultramarine hover:cursor-pointer"> 
              <Tooltip
                content={
                  <span style={{ whiteSpace: 'pre-line' }}>
                    <span className="text-sm">Response instructions will help you capture information in the desired way.</span>
                  </span>
                } className='bg-black py-1 px-2'
              >
                <ResponseIcon className='w-[14px] fill-superbill-slate-grey mx-1'/>
              </Tooltip>
                <span>
                  {responseInstruction}
                </span>
              </div>
              : null}
          <div className='mt-1 mb-0.5 text-xs text-superbill-ultra-red'>{/^\d{3}-\d{3}-\d{4}(\sext\s\d+)?$/.test(selectedOptions[questionId]) ? '' : 'Invalid phone. Use this format: 123-456-7890'}</div>
        </div>)
        break
      case 'faxNumber':
        return (
          <div style={{ marginLeft: 1.5*index, marginTop: '4px' }} key={'div_' + questionId}>
                <div className='flex items-center justify-between w-full'><div className='mt-1 mb-0.5 text-sm text-superbill-jacarta'>{formattedQuestion}</div><div className='mt-1 mb-0.5 text-xs text-superbill-jacarta'>Id:{questionId}</div></div>
                {backgroundContext ? 
              <div className="bg-superbill-indigo/10 text-xs text-superbill-jacarta flex items-ceneter py-1 rounded-tr hover:text-superbill-ultramarine hover:cursor-pointer"> 
              <Tooltip
                content={
                  <span style={{ whiteSpace: 'pre-line' }}>
                    <span className="text-sm">Background information can help you communicate with the representative.</span>
                  </span>
                } className='bg-black py-1 px-2'
              >
                <BackgroundIcon className='w-[14px] fill-superbill-jacarta mx-1'/>
              </Tooltip>
                <span>
                  {backgroundContext}
                </span>
              </div>
            : null}
                <input
                  key={'input_' + questionId}
                  name={'name_' + questionId}
                  type="text"
                  placeholder="Use this format: 123-456-7890"
                  value={selectedOptions[questionId]}
                  className={`w-full rounded border-superbill-soap focus:border-superbill-indigo focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-base`}
                  onChange={(e) => {
                    const inputValue = e.target.value
                    //console.log('inputValue', inputValue);
                    //let formattedValue = inputValue?.replace(/[^\d-]/g, '').slice(0, 12).replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
                    e.target.value = inputValue
                    handleChange(questionId, inputValue)

                  }}
                />
                {responseInstruction? 
              <div className="mt-1 text-xs text-superbill-slate-grey flex items-ceneter py-1 mb-3 hover:text-superbill-ultramarine hover:cursor-pointer"> 
              <Tooltip
                content={
                  <span style={{ whiteSpace: 'pre-line' }}>
                    <span className="text-sm">Response instructions will help you capture information in the desired way.</span>
                  </span>
                } className='bg-black py-1 px-2'
              >
                <ResponseIcon className='w-[14px] fill-superbill-slate-grey mx-1'/>
              </Tooltip>
                <span>
                  {responseInstruction}
                </span>
              </div>
              : null}
                <div className='mt-1 mb-0.5 text-xs text-superbill-ultra-red'>{/^\d{3}-\d{3}-\d{4}(\sext\s\d+)?$/.test(selectedOptions[questionId]) ? '' : 'Invalid fax number. Use this format: 123-456-7890 or 123-456-7890 ext 123'}</div>
          </div>
        )
        break
      case 'address':
        return (
          <div style={{ marginLeft: 1.5*index, marginTop: '4px' }} key={'div_' + questionId}>
            <div className='flex items-center justify-between w-full'><div className='mt-1 mb-0.5 text-sm text-superbill-jacarta'>{formattedQuestion}</div><div className='mt-1 mb-0.5 text-xs text-superbill-jacarta'>Id:{questionId}</div></div>
            {backgroundContext ? 
              <div className="bg-superbill-indigo/10 text-xs text-superbill-jacarta flex items-ceneter py-1 rounded-tr hover:text-superbill-ultramarine hover:cursor-pointer"> 
              <Tooltip
                content={
                  <span style={{ whiteSpace: 'pre-line' }}>
                    <span className="text-sm">Background information can help you communicate with the representative.</span>
                  </span>
                } className='bg-black py-1 px-2'
              >
                <BackgroundIcon className='w-[14px] fill-superbill-jacarta mx-1'/>
              </Tooltip>
                <span>
                  {backgroundContext}
                </span>
              </div>
            : null}
            <input
              key={'input_' + questionId}
              name={'name_' + questionId}
              type="text"
              placeholder="Address Line 1, Address Line 2, City, State, Zip"
              value={selectedOptions[questionId]}
              className={`w-full rounded border-superbill-soap focus:border-superbill-indigo focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-base`}
              onChange={(e) => {
                const inputValue = e.target.value//.replace(/[^a-zA-Z0-9 $\-.,@+=]/g, "")
                // free text 4 now
                /* if (/^(\d+.+,.+\d{5,}|UNKNOWN)$/.test(inputValue)) {
                  e.target.value = inputValue;
                  handleChange(questionId, inputValue);
                } */
                e.target.value = inputValue;
                handleChange(questionId, inputValue);
              }}
            />
            {responseInstruction? 
              <div className="mt-1 text-xs text-superbill-slate-grey flex items-ceneter py-1 mb-3 hover:text-superbill-ultramarine hover:cursor-pointer"> 
              <Tooltip
                content={
                  <span style={{ whiteSpace: 'pre-line' }}>
                    <span className="text-sm">Response instructions will help you capture information in the desired way.</span>
                  </span>
                } className='bg-black py-1 px-2'
              >
                <ResponseIcon className='w-[14px] fill-superbill-slate-grey mx-1'/>
              </Tooltip>
                <span>
                  {responseInstruction}
                </span>
              </div>
              : null}
          </div>
        )
      case 'multiple':
        let mOptions =answerOptions?.map((e) => {
          return { value: e, label: e } 
        })
        return (<div style={{ marginLeft: 1.5*index, marginTop: '4px' }} key={'div_' + questionId}>
          <div className='flex items-center justify-between w-full'><div className='mt-1 mb-0.5 text-sm text-superbill-jacarta'>{formattedQuestion}</div><div className='mt-1 mb-0.5 text-xs text-superbill-jacarta'>Id:{questionId}</div></div>
          {backgroundContext ? 
              <div className="bg-superbill-indigo/10 text-xs text-superbill-jacarta flex items-ceneter py-1 rounded-tr hover:text-superbill-ultramarine hover:cursor-pointer"> 
              <Tooltip
                content={
                  <span style={{ whiteSpace: 'pre-line' }}>
                    <span className="text-sm">Background information can help you communicate with the representative.</span>
                  </span>
                } className='bg-black py-1 px-2'
              >
                <BackgroundIcon className='w-[14px] fill-superbill-jacarta mx-1'/>
              </Tooltip>
                <span>
                  {backgroundContext}
                </span>
              </div>
            : null}
          <Select
            value={mOptions?.find(option => option.value === selectedOptions[questionId])}
            onChange={(selectedOption) => handleChange(questionId, selectedOption as Option, true)}
            options={mOptions}
          />
          {responseInstruction? 
              <div className="mt-1 text-xs text-superbill-slate-grey flex items-ceneter py-1 mb-3 hover:text-superbill-ultramarine hover:cursor-pointer"> 
              <Tooltip
                content={
                  <span style={{ whiteSpace: 'pre-line' }}>
                    <span className="text-sm">Response instructions will help you capture information in the desired way.</span>
                  </span>
                } className='bg-black py-1 px-2'
              >
                <ResponseIcon className='w-[14px] fill-superbill-slate-grey mx-1'/>
              </Tooltip>
                <span>
                  {responseInstruction}
                </span>
              </div>
              : null}
          {answerOptions?.map((e) => {
            let value = selectedOptions[questionId]?.value ?? selectedOptions[questionId];
            return hasChildren && (value === e) && renderQuestions((questions || [])?.filter(q => q.parentAnswer === value), p)
          })}
        </div>

        )
        /* return (
          <div style={{ marginLeft: 1.5*index, marginTop: '4px' }} key={'div_' + questionId}>
            <div className='flex items-center justify-between w-full'><div className='mt-1 mb-0.5 text-sm text-superbill-jacarta'>{formattedQuestion}</div><div className='mt-1 mb-0.5 text-xs text-superbill-jacarta'>Id:{questionId}</div></div>
            {(answerOptions ?? []).map((option, optionIndex) => (
              <div key={optionIndex}>
                <input
                  type="radio"
                  id={`option_${optionIndex}`}
                  name={`options_${questionId}`}
                  value={option}
                  checked={ 
                    (selectedOptions[questionId] === option) ||
                    (!answerOptions?.includes(selectedOptions[questionId]) && option.toLowerCase() === 'other')
                  }
                  onChange={(e) => handleChange(questionId, e.target.value)}
                />
                <label htmlFor={`option_${optionIndex}`} className="ml-2">{option}</label>
              </div>
            ))}

            { selectedOptions[questionId]?.toLowerCase() === 'other' || !answerOptions?.includes(selectedOptions[questionId]) ? (
              <input
                type="text"
                placeholder="Other (max 15 characters)"
                maxLength={15}
                value={selectedOptions[questionId]}
                onChange={(e) => {
                  const inputValue = e.target.value?.replace(/[^a-zA-Z0-9\s\.\,\-\$\@]+$/, "")
                  e.target.value = inputValue;
                  handleChange(questionId, inputValue)
                }}
                className="mt-2 w-full rounded border-superbill-soap focus:border-superbill-indigo focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-base"
              />
            ): null}
            
            {hasChildren && selectedOptions[questionId] && renderQuestions((questions || [])?.filter(q => {
              console.log(q);
              return q.parentAnswer === selectedOptions[questionId]
            }), p)}
           
          </div>
        ); */

      default:
        //text
        return (
          <div style={{ marginLeft: 1.5*index, marginTop: '4px' }} key={'div_' + questionId}>
            <div className='flex items-center justify-between w-full'><div className='mt-1 mb-0.5 text-sm text-superbill-jacarta'>{formattedQuestion}</div><div className='mt-1 mb-0.5 text-xs text-superbill-jacarta'>Id:{questionId}</div></div>
            {backgroundContext ? 
              <div className="mt-1 bg-superbill-indigo/10 text-xs text-superbill-jacarta flex items-ceneter py-1 rounded-tr hover:text-superbill-ultramarine hover:cursor-pointer"> 
              <Tooltip
                content={
                  <span style={{ whiteSpace: 'pre-line' }}>
                    <span className="text-sm">Background information can help you communicate with the representative.</span>
                  </span>
                } className='bg-black py-1 px-2'
              >
                <BackgroundIcon className='w-[14px] fill-superbill-jacarta mx-1'/>
              </Tooltip>
                <span>
                  {backgroundContext}
                </span>
              </div>
            : null}
            <input
              key={'input_' + questionId}
              name={'name_' + questionId}
              type="text"
              placeholder="Enter a question"
              value={selectedOptions[questionId]}
              className={`w-full rounded border-superbill-soap focus:border-superbill-indigo focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-base`}
              onChange={(e) => {
                const inputValue = e.target.value?.replace(/[^a-zA-Z0-9\s\'\.\,\-\$\@\:]+$/, "")
                e.target.value = inputValue;
                handleChange(questionId, inputValue)
              }}
            />
            {responseInstruction? 
              <div className="mt-1 text-xs text-superbill-slate-grey flex items-ceneter py-1 mb-3 hover:text-superbill-ultramarine hover:cursor-pointer"> 
              <Tooltip
                content={
                  <span style={{ whiteSpace: 'pre-line' }}>
                    <span className="text-sm">Response instructions will help you capture information in the desired way.</span>
                  </span>
                } className='bg-black py-1 px-2'
              >
                <ResponseIcon className='w-[14px] fill-superbill-slate-grey mx-1'/>
              </Tooltip>
                <span>
                  {responseInstruction}
                </span>
              </div>
              : null}
            <div className='mt-1 mb-0.5 text-xs text-superbill-ultra-red'>{/^[a-zA-Z0-9\s\.\:\,\-\$\@\']+$/.test(selectedOptions[questionId]) ? '' : 'Invalid input. Only letters, numbers, spaces, and the characters \'-:.,@$. are allowed'}</div>
          </div>
        )

    }
  };

  const renderQuestions = (questions: Question[], index) => {
    return questions.map((questionObj) => {
      return renderQuestion(questionObj, index);
    });
  };


  return (
    <div className='mb-40'>
      <form id='script-form'>
        {questions.map((questionObj) => renderQuestion(questionObj))}
      </form>
    </div>
  );
};

export default FormComponent;
