import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as Spinner } from 'assets/button-loading.svg';
import { useForm } from "react-hook-form";
import Modal from "react-modal";
import { updateInstructions } from "../../apis/agent";

// custom styles
const customStyles = {
  content: {
    height: "max(70%,550px)",
    maxWidth: "max(70%,550px)",
    margin: "auto",
    padding: 0,
    borderRadius: "10px",
    border: "none",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.08)"
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

interface Props {
  modalIsOpen: boolean;
  setSelectedScript: React.Dispatch<React.SetStateAction<number | null>>;
  data:{
    scriptId: string;
    robodialerId: string;
    instructions?: string | null
  }
  onSuccess: () => void
}


export default function EditInstructionsModal({ modalIsOpen, setSelectedScript, data, onSuccess }: Props) {

  const { register, handleSubmit, reset, watch, setValue, formState: { errors } } = useForm();
  
  const [isLoading, setIsLoading] = useState<boolean>(false)
  
  const onSubmit = async (formData) => {
    console.log(data, formData);
    setIsLoading(true)
    
    const { httpCode } = await updateInstructions(data.robodialerId, data.scriptId, {instructions:watch('instructions')})
    if(httpCode == 200 && onSuccess){
      onSuccess()
    }
    setIsLoading(false)
    toggleModal();
  };

  useEffect(() => {
    setValue('instructions', data.instructions??"")
  }, [modalIsOpen])

  

  const toggleModal = () => {
    reset()
    setSelectedScript(null);
  };

  return (
    <div>
      <Modal
        ariaHideApp={false}
        isOpen={modalIsOpen}
        onRequestClose={toggleModal}
        style={customStyles}
        contentLabel="Modal"
      >
        <div className="flex flex-col h-full divide-y font-sans">
          {/* Navbar */}
          <nav className="font-jakarta font-bold px-6 pt-7 pb-4 text-xl text-superbill-jacarta">
            Instructions
          </nav>
          <div className="flex-grow overflow-y-auto p-9 !z-50">
          <form className="flex flex-col gap-4">
            <label htmlFor="instructions" className="text-superbill-jacarta text-sm">Instructions:</label>
              <textarea
                {...register("instructions")}
                className="p-4 rounded border-superbill-soap focus:border-superbill-indigo focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-base"
                placeholder="Type here..."
              />
              {errors.areaText && <p className="text-red-500">Only letters, numbers, spaces, dots, and commas are allowed in the text area.</p>}
            </form>
          </div>
          {/* Toolbar */}
          <div className="flex flex-row justify-between px-4 py-3">
            <button
              className='bg-transparent font-jakarta hover:bg-superbill-lavender-dark/50 text-superbill-jacarta font-semibold py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300'
              onClick={() => { setSelectedScript(null) }}
            >
              Close
            </button>
            <button
              disabled={isLoading}
              className='bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300'
              onClick={handleSubmit(onSubmit)}
            >
              {isLoading ? <Spinner /> : <>Submit</>}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
