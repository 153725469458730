import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import QuestionInput from "./QuestionInput";
import QuestionSelect from "./QuestionSelect";
import Subquestions from "./Subquestions";
import { NewQuestion, NewSubQuestion, Option, QuestionType, Questions } from "./QuestionType";
import QuestionMenu from "./QuestionOptions";
import selectOptions from "./QuestionSelectOptions";
import { createScript, getScriptInputs, getScriptsTypes } from "apis/robodialer";
import InsuranceForm from "./pms-form";
import { Script } from "types/types";
import RightPanel from "script-builder/create-script/right-panel";
import { ReactComponent as QuestionIcon } from 'assets/question-icon.svg';
import { ReactComponent as XIcon } from 'assets/x.svg';
import { ReactComponent as PlusIcon } from 'assets/plus-circle.svg';
import Accordion from "../../components/general/accordion";
import CalendlyModal from "./CalendlySuperdial";
import StyledQuestionInput from "./StyledQuestionInput";
import toast, { Toaster } from "react-hot-toast";
import { ReactComponent as BackgroundIcon } from 'assets/icon_backgroundInfo.svg';
import { ReactComponent as ResponseIcon } from 'assets/icon_responseInstructions.svg';
import { ReactComponent as DeleteIcon } from 'assets/icon_delete.svg';
import Modal from "react-modal";
import BackgroundInfoModal from "./background-info-modal";
import ResponseInstructionsModal from "./response-instructions-modal";



const newEntry = {
  question: '',
  answerType: { value: '', label: '' },
};

// custom styles
const customStyles = {
  content: {
    height: "max(30%,300px)",
    maxWidth: "max(50%,400px)",
    margin: "auto",
    padding: 0,
    borderRadius: "10px",
    border: "none",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.08)"
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: '9999'
  },
};

interface Props {
  questions: Array<Questions>;
  onSuccess?: (e: Array<Questions>, externalInstructions: string, pmsForm?: any) => void;
  externalInstructions: string;
  setExternalInstructions: React.Dispatch<React.SetStateAction<string>>;
  scriptData?: Script | null;

  setQuestions: React.Dispatch<React.SetStateAction<Array<Questions> | null>>;
  showPmsForm?: boolean;
}

const QuestionPage = forwardRef(({ questions, setQuestions, onSuccess, externalInstructions, setExternalInstructions, scriptData, showPmsForm = false }: Props, ref) => {

  const [dragIndex, setDragIndex] = useState(null);
  const [dragSubIndex, setDragSubIndex] = useState(null);
  const [rightPanelOpen, setRightPanelOpen] = useState<boolean>(false);
  const [calendlyModalIsOpen, setCalendlyModalIsOpen] = useState<boolean>(false);
  const [inputs, setInputs] = useState({});
  const [scriptType, setScriptType] = useState<string>('');
  
  const { register, reset, unregister, watch, setValue, control, handleSubmit, formState: { errors }, } = useForm<Questions>();
  const pmsForm = useForm();
  const formRef = useRef<HTMLFormElement>(null);
  const [formData, setFormData] = useState<Questions | null>(null);
  const [showBackgroundModal, setShowBackgroundModal] = useState<boolean>(false);
  const [showResponseModal, setShowResponseModal] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState<number | null | string>(null);
  const [localBackgroundText, setLocalBackgroundText] = useState<string>('');
  const [localResponseText, setLocalResponseText] = useState<string>('');
  const [editBackgroundIndex, setBackgroundEditIndex] = useState<number | null>(null);
  const [editResponseIndex, setResponseEditIndex] = useState<number | null>(null);
  const [tempBackgroundText, setTempBackgroundText] = useState<string>("");
  const [tempResponseText, setTempResponseText] = useState<string>("");


  const extractAnswerTypeValue = (answerType: string | Option | undefined): string | null => {
    if (typeof answerType === 'string') {
      return answerType;
    } else {
      return answerType?.value || null;
    }
  }

  useEffect(() => {
    const fetch = async () => {
      const { data } = await getScriptInputs(scriptData?.type?.replaceAll(' ', '-').toLocaleLowerCase())
      setInputs(data);
    }
    if (scriptData && scriptData?.type) {
      fetch()
    }

  }, [scriptData?.type])

  useEffect(() => {

    const fetch = async () => {
      const { httpCode, data } = await getScriptsTypes()
      if (httpCode === 200) {
        setScriptType((data.scripts.find(e => e.id === scriptData?.type))?.name || '');

      }
    }
    fetch()
  }, [scriptData?.type])

  console.log('questions', questions);
  

  const unparse = (input?: string): string | undefined => {
    if (input) {
      return (input.replace(/<code class="[^"]*">([^<]*)<\/code>/g, (match, content) => `${content}`)).trim();
    }
    return input
  }

  const onSubmit = async () => {
    let errorCounter = 0; // Initialize the error counter

    let unparsedExternalInstructions = externalInstructions;

    if (unparsedExternalInstructions.indexOf('text-superbill-ultra-red') !== -1) {
      errorCounter++;
    } else {
      unparsedExternalInstructions = unparse(externalInstructions)!
    }

    const arr = Object.values(questions ?? [{ ...newEntry }]).map(({ answerType, followUps, question, ...rest }) => {
      let answer = typeof answerType === 'string' ? selectOptions.find(option => option.value === answerType) : answerType;
      //console.log('question', question, answerType);

      let obj = {
        answerType: typeof answer === 'string' ? answer : answer?.value,
        followUps: {},
        question: unparse(question),
        ...rest
      };
      if ((typeof question !== 'undefined' && (!question?.trim() || question?.indexOf('text-superbill-ultra-red') !== -1)) ||
        (typeof answerType === 'string' && !answerType?.trim()) ||
        (typeof answerType === 'object' && !answerType?.value?.trim())) {
        errorCounter++;
      }
      function processLevel(data){
        if (!data) {
            return data;
        }
    
        if (Array.isArray(data)) {
            return data.map(item => processLevel(item)) as Array<NewQuestion>;
        }
    
        if (typeof data === 'object') {
            if (data.question) {
                data.question = unparse(data.question);
            }
            if ((typeof data.question !== 'undefined' && (!data.question?.trim() || data.question?.indexOf('text-superbill-ultra-red') !== -1)) ||
                (typeof data.answerType === 'string' && !data.answerType?.trim()) ||
                (typeof data.answerType === 'object' && !data.answerType?.value?.trim())) {
                errorCounter++;
            }
            for (const key in data.followUps?.multiple) {
                data.followUps.multiple[key] = processLevel(data.followUps?.multiple[key]);
            }
            for (const key in data.followUps) {
                if (key !== 'multiple') {
                    data.followUps[key] = data.followUps[key]?.map(item => processLevel(item)) as Array<NewQuestion>;
                }
            }
        }
    
        return data;
    }
    



      if (followUps) {
        obj.followUps = {
          Yes: followUps.Yes?.map(({ answerType, followUps, question, ...rest }) => {
            let subAnswer = typeof answerType === 'string' ? selectOptions.find(option => option.value === answerType) : answerType;
            let subObj = {
              answerType: typeof subAnswer === 'string' ? subAnswer : subAnswer?.value,
              followUps: {},
              question: unparse(question),
              ...rest
            };
            //console.log('question', question, answerType);
            if ((typeof question !== 'undefined' && (!question?.trim() || question?.indexOf('text-superbill-ultra-red') !== -1)) ||
              (typeof answerType === 'string' && !answerType?.trim()) ||
              (typeof answerType === 'object' && !answerType?.value?.trim())) {
              errorCounter++;
            }
            if (followUps) {
              subObj.followUps = {
                Yes: processLevel(followUps.Yes),
                No: processLevel(followUps.No),
                multiple: followUps.multiple ? processLevel(followUps.multiple) : undefined
              };
            }

            return subObj;
          }),
          No: followUps.No?.map(({ answerType, followUps, question, ...rest }) => {
            let subAnswer = typeof answerType === 'string' ? selectOptions.find(option => option.value === answerType) : answerType;
            let subObj = {
              answerType: typeof subAnswer === 'string' ? subAnswer : subAnswer?.value,
              followUps: {},
              question: unparse(question),
              ...rest
            };
            //console.log('question', question, answerType);
            if ((typeof question !== 'undefined' && (!question?.trim() || question?.indexOf('text-superbill-ultra-red') !== -1)) ||
              (typeof answerType === 'string' && !answerType?.trim()) ||
              (typeof answerType === 'object' && !answerType?.value?.trim())) {
              errorCounter++;
            }
            if (followUps) {
              subObj.followUps = {
                Yes: processLevel(followUps.Yes),
                No: processLevel(followUps.No),
                multiple: followUps.multiple ? processLevel(followUps.multiple) : undefined
              };
            }

            return subObj;
          }),
          multiple:  Object.keys(followUps.multiple || {}).reduce((acc, key) => {
            acc[key] = followUps.multiple ? processLevel(followUps.multiple[key]) : undefined
            return acc;
        }, {})
        };

        processLevel(obj.followUps);
      }

      return obj;
    });
    if (Object.keys(arr).length === 0) {
      errorCounter++;
      //console.log('###hErE finish');

    }
    //console.log('errorCounter', errorCounter);

    if (0 < errorCounter) {
      toast.error('Some inputs are not valid', {
        iconTheme: {
          primary: '#F36F82',
          secondary: '#ffffff',
        },
      });
    }
    if (onSuccess && errorCounter === 0) {
      onSuccess(arr as Array<Questions>, unparsedExternalInstructions!, pmsForm.watch())
    }

  };


  const handleDeleteQuestion = (index: number, name) => {
    const newQuestions = [...questions];
    newQuestions.splice(index, 1);
    setQuestions(newQuestions);
    unregister(name)
  };

  const duplicateQuestion = (index: number) => {
    const questionToDuplicate = questions[index];
    const newQuestions = [...questions];

    // Insert a copy of the question to duplicate at the next index
    newQuestions.splice(index + 1, 0, { ...questionToDuplicate });

    setQuestions(newQuestions);
  };

  const handleSubquestionsBackgroundContextAndResponseInstructions = (index: string | null, data, type: 'responseInstruction' | 'backgroundContext' = 'backgroundContext') => {
    const newQuestions = JSON.parse(JSON.stringify(questions));
    findAndUpdateQuestionValue(newQuestions, index, data, type);
    setQuestions(newQuestions);
  }

  const handleBackgroundContextAndResponseInstructions = (index: number, contextText, type: 'responseInstruction' | 'backgroundContext' = 'backgroundContext') => {
    const newQuestions = [...questions];
    if(typeof newQuestions[index][type] !== 'undefined'){
      console.log('si newQuestions[index][type]', newQuestions[index][type]);
      newQuestions[index][type] = contextText
    }
    else{
      console.log('no newQuestions[index][type]', newQuestions[index][type], newQuestions[index], type);
      const text = {}
      text[type] = contextText
      newQuestions[index] = {
        ...text,
        ...newQuestions[index],
      };
    }
    console.log(newQuestions[index])
    setQuestions(newQuestions);
    setSelectedIndex(null)
    setLocalBackgroundText('');
    if (type === 'backgroundContext') {
      setShowBackgroundModal(false)
    }else{
      setShowResponseModal(false)
    }
  };
  
  const handleBackgroundContextClick = (index: number) => {
    setBackgroundEditIndex(index);
    setTempBackgroundText(questions[index].backgroundContext || "");
  };

  const handleBackgroundInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTempBackgroundText(event.target.value);
  };

  const handleBackgroundInputBlur = (index: number) => {
    const newQuestions = [...questions];
    newQuestions[index] = {
      ...newQuestions[index],
      backgroundContext: tempBackgroundText,
    };
    setQuestions(newQuestions);
    setBackgroundEditIndex(null);
  };

  const handleDeleteBackgroundContext = (index: number) => {
    const newQuestions = [...questions];
    newQuestions[index] = {
      ...newQuestions[index],
      backgroundContext: "",
    };
    setQuestions(newQuestions);
    setBackgroundEditIndex(null);
  };
  
  const handleResponseInstructionClick = (index: number) => {
    setResponseEditIndex(index);
    setTempResponseText(questions[index].responseInstruction || "");
  };

  const handleResponseInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTempResponseText(event.target.value);
  };

  const handleResponseInputBlur = (index: number) => {
    const newQuestions = [...questions];
    newQuestions[index] = {
      ...newQuestions[index],
      responseInstruction: tempResponseText,
    };
    setQuestions(newQuestions);
    setResponseEditIndex(null);
  };

  const handleDeleteResponseInstruction = (index: number) => {
    const newQuestions = [...questions];
    newQuestions[index] = {
      ...newQuestions[index],
      responseInstruction: "",
    };
    setQuestions(newQuestions);
    setResponseEditIndex(null);
  };

  const navigateToSubquestion = (q, name) => {
    const parts = name.split(".");
    let parentQuestion = q[parts[1]];
    let currentQuestion = parentQuestion;

    for (let i = 2; i < parts.length - 1; i += 2) {
      const followUpType = parts[i];
      const followUpIndex = parts[i + 1];

      if (
        currentQuestion?.followUps &&
        currentQuestion.followUps[followUpType] &&
        currentQuestion.followUps[followUpType][followUpIndex]
      ) {
        parentQuestion = currentQuestion;
        currentQuestion = currentQuestion.followUps[followUpType][followUpIndex];
      } else {
        throw new Error("Invalid subquestion path");
      }
    }

    return { parentQuestion, currentQuestion };
  };

  const deleteSubquestion = (q, name) => {
    const { parentQuestion, currentQuestion } = navigateToSubquestion(q, name);
    const parts = name.split(".");

    const followUpTypeToDelete = parts[parts.length - 2];
    const followUpIndexToDelete = parts[parts.length - 1];

    if (
      parentQuestion?.followUps &&
      parentQuestion.followUps[followUpTypeToDelete] &&
      parentQuestion.followUps[followUpTypeToDelete][followUpIndexToDelete]
    ) {
      parentQuestion.followUps[followUpTypeToDelete].splice(followUpIndexToDelete, 1);
      unregister(name)
    } else {
      throw new Error("Subquestion not found");
    }
  };

  const duplicateSubquestion = (q, name) => {
    const { parentQuestion, currentQuestion } = navigateToSubquestion(q, name);
    const parts = name.split(".");

    const followUpTypeToDuplicate = parts[parts.length - 2];
    const followUpIndexToDuplicate = parts[parts.length - 1];

    if (
      parentQuestion?.followUps &&
      parentQuestion.followUps[followUpTypeToDuplicate] &&
      parentQuestion.followUps[followUpTypeToDuplicate][followUpIndexToDuplicate]
    ) {
      const subQuestionToDuplicate =
        parentQuestion.followUps[followUpTypeToDuplicate][followUpIndexToDuplicate];

      const duplicatedSubQuestion = { ...subQuestionToDuplicate };

      //const newFollowUpIndex = parentQuestion.followUps[followUpTypeToDuplicate].length;

      parentQuestion.followUps[followUpTypeToDuplicate].push(duplicatedSubQuestion);


    } else {
      throw new Error("Subquestion not found");
    }
  };

  const addQuestion = () => {
    setQuestions(prevState => [...prevState || [], { ...newEntry }]);
  }


  const deleteMultipleChoice = (question: Questions, index?: number) => {
    setQuestions(prevState => {
      const newQuestions = [...prevState || []];
      if (question?.answerOptions && 2 < question?.answerOptions.length) {
        if (typeof index !== 'undefined' && typeof question?.answerOptions[index] !== 'undefined') {
          if (question.followUps && question.followUps?.multiple && question.followUps?.multiple[question?.answerOptions[index]]) {
            delete question.followUps.multiple[question?.answerOptions[index]]
          }
          question.answerOptions.splice(index, 1)
        } else {
          delete question.answerOptions
          if (question.followUps && question.followUps?.multiple) {
            delete question.followUps.multiple
          }
        }
      }
      return newQuestions;
    });
  };


  const MAX_MC = 7;

  const addMultipleChoice = (question: Questions) => {
    setQuestions(prevState => {
      const newQuestions = [...prevState || []];

      if (!question?.answerOptions || question?.answerOptions.length === 0) {
        question.answerOptions = ['Option1', 'Option2', 'other'];
      } else {
        if (question?.answerOptions.length < MAX_MC) {
          question.answerOptions = ['', ...question.answerOptions]
        }
      }

      return newQuestions;

    });
  };

  const addSubQuestionForMC = (question: Questions, option?: string) => {
    setQuestions(prevState => {
      const newQuestions = [...(prevState || [])];
      const newEntry = {
        question: '',
        answerOptions: [],
        answerType: { value: '', label: '' },
      };
      if (!question.followUps?.multiple || Object.keys(question.followUps.multiple).length === 0) {
        question.followUps = { multiple: {} };
      }
      if (question.followUps.multiple![option || ''] && 0 < question.followUps.multiple![option || ''].length) {
        question.followUps.multiple![option || ''].push({ ...newEntry })
      } else {
        question.followUps.multiple![option || ''] = [{ ...newEntry }]
      }

      return newQuestions;
    });
  };

  const addSubQuestion = (question: Questions, isFollowUpYes?: boolean) => {
    setQuestions(prevState => {
      const newQuestions = [...prevState || []];
      let newSub: NewSubQuestion = {
        Yes: isFollowUpYes === true ? [{ ...newEntry }] : [],
        No: isFollowUpYes === false ? [{ ...newEntry }] : [],
      }

      if (!question?.followUps || Object.keys(question?.followUps).length === 0) {
        question.followUps = newSub;
      } else {
        let type = isFollowUpYes ? "Yes" : "No";
        if (!question?.followUps?.[type]) {
          question.followUps![type] = [{ ...newEntry }]
        } else {
          question?.followUps?.[type]?.push({ ...newEntry })
        }
      }
      return newQuestions;
    });
  };


  const addSubQuestionToFollowUps = (index: number, subindex: number, isFollowUpYes?: boolean) => {
    setQuestions(prevState => {
      const newQuestions = [...prevState || []];

      let newSub: NewSubQuestion = {
        Yes: isFollowUpYes === true ? [{ ...newEntry }] : [],
        No: isFollowUpYes === false ? [{ ...newEntry }] : [],
      }
      //console.log('newSub', newSub);

      if (!newQuestions[index].followUps![subindex].followUps) {
        newQuestions[index].followUps![subindex].followUps = newSub;
      } else {
        let type = isFollowUpYes ? "Yes" : "No";
        if (!newQuestions[index].followUps?.[type]) {
          newQuestions[index].followUps![subindex].followUps![type] = [{ ...newEntry }]
        } else {
          newQuestions[index].followUps![subindex].followUps?.[type]?.push({ ...newEntry })
        }
      }
      return newQuestions;
    });
  };


  const handleDragEnter = (e, index) => {
    e.preventDefault();
    e.stopPropagation();
    if (index) {
      setDragIndex(index);
    }
  };

  const handleDragSubEnter = (e, name) => {
    e.preventDefault();
    e.stopPropagation();
    //console.log('handleDragSubEnter', name);
    if (name) {
      setDragSubIndex(name);
    }
  };

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData('index', index);
  };
  const handleDragSubStart = (e, name) => {
    e.dataTransfer.setData('subIndex', name);
  };

  const handleDrop = (e, newIndex) => {
    e.preventDefault();
    e.stopPropagation();
    const oldIndex = e.dataTransfer.getData('index');
    const newQuestions = [...questions];


    const [removed] = newQuestions.splice(oldIndex, 1);
    newQuestions.splice(newIndex, 0, removed);
    setQuestions(newQuestions);
    setDragIndex(null);
  };

  const handleDragLeave = (e) => {
    setDragIndex(null);
  };

  const handleDragSubLeave = (e) => {
    setDragSubIndex(null);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };


  const handleInputChange = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = e.target;

    const newQuestions = [...questions];
    newQuestions[index].question = value;
    setQuestions(newQuestions);
  };

  const handleOptionInputChange = (
    index: number,
    optionIndex: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = e.target;
    const newQuestions = [...questions];
    //console.log(newQuestions[index].answerOptions![optionIndex]);
    newQuestions[index].answerOptions![optionIndex] = value;
    setQuestions(newQuestions);
  };

  const handleQuestionTypeChange = (index: number, option) => {
    const newQuestions = [...questions];
    if ((typeof option === 'string' && option !== 'boolean') || (typeof option === 'object' && option?.value !== 'boolean')) {
      delete newQuestions[index]?.followUps
      delete newQuestions[index]?.answerOptions
    }
    if ((typeof option === 'string' && option !== 'multiple') || (typeof option === 'object' && option?.value !== 'multiple')) {
      delete newQuestions[index]?.answerOptions
    }
    if ((typeof option === 'string' && option === 'multiple') || (typeof option === 'object' && option?.value === 'multiple')) {
      newQuestions[index].answerType = option?.value;
      newQuestions[index].answerOptions = ['Option1', 'Option2', 'other'];
    }
    newQuestions[index].answerType = option?.value;
    setQuestions(newQuestions);
  }

  const findAndUpdateQuestionValue = (q, name, value, type) => {
    //console.log('q', q);
    console.log('name', name);
    console.log('type', type);
    let set = true
    const parts = name.split('.');
    let currentQuestion = q[parts[1]];
    console.log('currentQuestion', currentQuestion);

    for (let i = 2; i < parts.length; i += 2) {
      const currentPart = parts[i];
      const currentIndex = parts[i + 1];
      if(currentPart === 'question' ||
         currentIndex === 'question' ){
          break
        }
      if (currentPart === 'answerOptions') {
        currentQuestion[currentPart][currentIndex] = value;
        set = false
        break
      } else if (currentPart === 'multiple') {
        const optionName = parts[i + 1];
        const optionIndex = parts[i + 2];
        currentQuestion = currentQuestion.followUps[currentPart][optionName][optionIndex];
        i ++;
      } else {
        currentQuestion = currentQuestion.followUps[currentPart][currentIndex];
      }
    }

    if( type==='answerType' ){
      if (value === 'multiple') {
        currentQuestion.answerOptions = ['Option1', 'Option2', 'other'];
        //addMultipleChoice(currentQuestion)
      } else {
        //console.log('delete', currentQuestion);
        delete currentQuestion.answerOptions
        //deleteMultipleChoice(currentQuestion)
      }

    }
    if(set){
      currentQuestion[type] = value;
    }
  };



  const handleSubQuestionInputChange = (e, iName: null | string = null, iValue: null | string = null) => {
    if (!iName || iValue === null) {
      const { name, value } = e.target;
      iName = name;
      iValue = value;
    };
    const newQuestions = JSON.parse(JSON.stringify(questions));
    findAndUpdateQuestionValue(newQuestions, iName, iValue, 'question');
    setQuestions(newQuestions);
    //setQuestions(newQuestions);
  };


  const handleSubQuestionSelectChange = (name, option) => {
    //const newQuestions = [...questions];
    const newQuestions = JSON.parse(JSON.stringify(questions));
    findAndUpdateQuestionValue(newQuestions, name, option.value, 'answerType');
    setQuestions(newQuestions);
    //setQuestions(newQuestions);
  };

  const handleDeleteSubQuestion = (name) => {
    const newQuestions = [...questions];
    try {
      deleteSubquestion(newQuestions, name);
      reset({ [name]: '' });
    } catch (error) {
      //console.error(error);
    }
    setQuestions(newQuestions);
  };

  const handleDuplicateSubQuestion = (name) => {
    const newQuestions = [...questions];
    try {
      duplicateSubquestion(newQuestions, name);
      //reset({ [name]: '' });
    } catch (error) {
      //console.error(error);
    }
    setQuestions(newQuestions);
  };

  const handleSubQuestionBackgroundChange = (name, text) => {
    //const newQuestions = [...questions];
    const newQuestions = JSON.parse(JSON.stringify(questions));
    findAndUpdateQuestionValue(newQuestions, name, text, 'backgroundContext');
    setQuestions(newQuestions);
    //setQuestions(newQuestions);
  };

  const handleSubQuestionResponseChange = (name, text) => {
    //const newQuestions = [...questions];
    const newQuestions = JSON.parse(JSON.stringify(questions));
    findAndUpdateQuestionValue(newQuestions, name, text, 'responseInstruction');
    setQuestions(newQuestions);
    //setQuestions(newQuestions);
  };



  const handleDropSubquestion = (e, targetName) => {
    e.preventDefault();
    e.stopPropagation();

    const draggedName = e.dataTransfer.getData('subIndex');

    const draggedSubquestionName = draggedName.split('.');
    const targetSubquestionName = targetName.split('.');

    const draggedHierarchy = draggedSubquestionName.length - 1;
    const targetHierarchy = targetSubquestionName.length - 1;
    if (draggedHierarchy !== targetHierarchy) {
    }

    const newQuestions = JSON.parse(JSON.stringify(questions));

    const draggedResult = navigateToSubquestion(newQuestions, draggedName);
    const targetResult = navigateToSubquestion(newQuestions, targetName);

    const draggedParentQuestion = draggedResult.parentQuestion;
    const targetParentQuestion = targetResult.parentQuestion;
    //const draggedCurrentQuestion = draggedResult.currentQuestion;
    //const targetCurrentQuestion = targetResult.currentQuestion;

    const draggedIndex = parseInt(draggedSubquestionName[draggedSubquestionName.length - 1], 10);
    const targetIndex = parseInt(targetSubquestionName[targetSubquestionName.length - 1], 10);

    const draggedFollowUpKey = draggedSubquestionName[draggedSubquestionName.length - 2];
    const targetFollowUpKey = targetSubquestionName[targetSubquestionName.length - 2];

    const temp = draggedParentQuestion.followUps[draggedFollowUpKey][draggedIndex];
    draggedParentQuestion.followUps[draggedFollowUpKey][draggedIndex] = targetParentQuestion.followUps[targetFollowUpKey][targetIndex];
    targetParentQuestion.followUps[targetFollowUpKey][targetIndex] = temp;

    setQuestions(newQuestions);
    setDragSubIndex(null);
  };

  const handleBackgroundInfoModalState = (index: number | string | null, state: boolean) => {
    setSelectedIndex(index);
    setShowBackgroundModal(state)
  }

  const handleResponseInstructionsModalState = (index: number | string | null, state: boolean) => {
    setSelectedIndex(index);
    setShowResponseModal(state)
  }
  useImperativeHandle(ref, () => ({ onSubmit }));

  return (
    <div className="">
      <Toaster  toastOptions={{duration: 12000}}/>
      <div className="flex w-full">
        <div className="max-w-[1000px] mx-auto mt-8 grow px-2">
          {scriptData?.pms && showPmsForm ? <InsuranceForm register={pmsForm.register} fieldValues={{ coverage: scriptData?.coverage, general: scriptData?.general, payments: scriptData?.payments, ...{ benefits: scriptData?.benefits } }} /> : null}
          {!scriptData?.pms || !showPmsForm ?
            <form onSubmit={(e) => { e.preventDefault() }} ref={formRef} className="flex flex-col">
              {/* <Accordion title='Script Instructions for the Virtual Agent'
                content={
                  <>
                    <div className='text-superbill-slate-grey text-base mb-2'>
                      Script instructions will be used by our virtual agent to better understand your scenario. Instructions are optional but may improve call success rate - our agent  knows that you are placing a call about {scriptType ? scriptType : scriptData?.type}, and any additional details about the specifics of your request will improve its knowledge of the situation.
                    </div>
                    <div className='w-full bg-white rounded p-2'>
                      <StyledQuestionInput
                        allowedString={scriptData?.inputs ?? []} index={'q.externalInstructions'} register={register} error={errors.question?.message}
                        iKey={'q.externalInstructions'}
                        value={externalInstructions}
                        onChange={(e) => setExternalInstructions(e.target.value)}
                        className='border-white my-1'
                      />
                    </div>
                  </>
                }
              /> */}
              <ul>

                {questions?.map((question, index) =>
                  <>
                    {typeof question?.question !== 'undefined' ?
                      <>
                        <div className="bg-white rounded-t w-fit px-3 ml-4 text-superbill-slate-grey text-sm">
                          {index}
                        </div>
                        <div className={"flex  mb-2 rounded hover:shadow" + (dragIndex === index ? ' bg-superbill-lavender-dark rounded' : ' bg-white ')} key={index}
                        >

                          <div className="cursor-pointer flex-grow-0 bg-superbill-indigo rounded-l "
                            onDragOver={handleDragOver}
                            onDragEnter={(e) => handleDragEnter(e, index)}
                            onDragLeave={handleDragLeave}
                            draggable
                            onDragStart={(e) => handleDragStart(e, index)}
                            onDrop={(e) => handleDrop(e, index)}

                          >
                            <div className={"flex items-center justify-center m-auto h-full px-1 text-white font-jakarta text-xs font-semibold " + (index > 8 ? 'w-[22px]' : 'w-[15px]')}>
                              {index + 1}
                            </div>
                          </div>
                          <div className="flex-grow h-auto rounded-r">
                            {question.backgroundContext? 
                            <div className="bg-superbill-indigo/10 text-xs text-superbill-jacarta flex items-ceneter py-1 rounded-tr hover:text-superbill-ultramarine hover:cursor-pointer"> 
                            <BackgroundIcon className='w-[14px] fill-superbill-jacarta mx-1'/>
                            {editBackgroundIndex === index ? (
                              <div className="flex items-center w-full">
                              <input
                                type="text"
                                value={tempBackgroundText}
                                onChange={handleBackgroundInputChange}
                                onBlur={() => handleBackgroundInputBlur(index)}
                                autoFocus
                                className="border-superbill-soap focus:ring focus:ring-indigo-200 focus:ring-opacity-50 focus:outline-none focus:border-superbill-indigo bg-white border rounded text-superbill-jacarta text-xs p-0 pl-1 grow"
                              />
                              <button
                                onMouseDown={(e) => e.preventDefault()}
                                onClick={() => handleDeleteBackgroundContext(index)}
                                className="ml-2 text-red-500 hover:text-red-700 w-[20px] flex-none"
                              >
                                <DeleteIcon className='w=[14px]'/>
                              </button>
                              </div>
                            ) : (
                              <span onClick={() => handleBackgroundContextClick(index)}>
                                {question.backgroundContext}
                              </span>
                            )}
                            </div>
                            : null
                            }
                            <div key={index} className="flex flex-col pt-2">
                              <div className="flex items-center justify-between">
                                <div className="mx-2 grow">
                                  <StyledQuestionInput
                                    allowedString={scriptData?.inputs ?? []} index={index} register={register} error={errors.question?.message}
                                    iKey={index}
                                    value={question.question}
                                    onChange={(e) => handleInputChange(index, e)}
                                    className='border-white'
                                  />
                                  {/* <QuestionInput index={index} register={register} error={errors.question?.message}
                              iKey={index}
                              value={question.question}
                              onChange={(e) => handleInputChange(index, e)}
                            /> */}
                                </div>
                                <div className="mr-2 w-[200px] min-w-[170px]">
                                  <QuestionSelect defaultValue={question?.answerType!} index={index} control={control} options={selectOptions/* .filter((e) => e.value !== 'multiple') */}
                                    onChange={(e) => {
                                      addMultipleChoice(question)
                                      handleQuestionTypeChange(index, e)
                                    }} />
                                </div>
                                <div className="flex mr-4 max-w-[30px] items-center justify-center mb-2 min-w-[30px]">
                                  <QuestionMenu deleteQ={() => { handleDeleteQuestion(index, `${index}.question`) }}
                                    canAddSubQuestions={(
                                      ((typeof question?.answerType !== 'string' && question?.answerType?.value === 'boolean') ||
                                        (typeof question?.answerType === 'string' && question?.answerType === 'boolean')
                                      ) &&
                                      ((typeof question?.followUps !== 'object') ||
                                        (Object.keys(question?.followUps).length === 0))
                                    )}
                                    duplicate={() => { duplicateQuestion(index) }} addSubQuestion={() => {
                                      addSubQuestion(question)
                                    }}
                                    addBackgroundContext={() => { 
                                      setSelectedIndex(index)
                                      setShowBackgroundModal(true)
                                    }}
                                    addResponseInstruction={() => { 
                                      setSelectedIndex(index)
                                      setShowResponseModal(true)
                                    }}
                                    />
                                </div>
                              </div>
                            </div>
                            {question.responseInstruction? 
                            <div className="text-xs text-superbill-slate-grey flex items-ceneter py-1 ml-4 mb-3 hover:text-superbill-ultramarine hover:cursor-pointer"> 
                            <ResponseIcon className='w-[14px] fill-superbill-slate-grey mx-1'/>
                            {editResponseIndex === index ? (
                              <div className="flex items-center w-full">
                              <input
                                type="text"
                                value={tempResponseText}
                                onChange={handleResponseInputChange}
                                onBlur={() => handleResponseInputBlur(index)}
                                autoFocus
                                className="border-superbill-soap focus:ring focus:ring-indigo-200 focus:ring-opacity-50 focus:outline-none focus:border-superbill-indigo bg-white border rounded text-superbill-jacarta text-xs p-0 pl-1 grow"
                              />
                              <button
                                onMouseDown={(e) => e.preventDefault()}
                                onClick={() => handleDeleteResponseInstruction(index)}
                                className="ml-2 text-red-500 hover:text-red-700 w-[20px] flex-none"
                              >
                                <DeleteIcon className='w=[14px]'/>
                              </button>
                              </div>
                            ) : (
                              <span onClick={() => handleResponseInstructionClick(index)}>
                                {question.responseInstruction}
                              </span>
                            )}
                            </div>
                            : null
                            }
                            {/* {question?.answerOptions && 0 < question?.answerOptions?.length ?
                              <div className="p-2 ml-3 ">
                                <div className="mb-2 flex items-center">
                                  <span className=" text-superpay-light-text-gray font-medium font-sans text-sm">Multiple choice options</span>
                                </div>
                                <div className='w-full flex flex-col items-start align-middle gap-y-2'>
                                  {question?.answerOptions.map((option, optionIndex) =>
                                    <div className='flex gap-x-4 w-full items-center' key={`${index}.multiple.${optionIndex}`}>
                                      <StyledQuestionInput
                                        allowedString={scriptData?.inputs ?? []} index={`${index}.${optionIndex}`} register={register} error={errors.question?.message}
                                        iKey={'multiple.' + optionIndex}
                                        value={option}
                                        onChange={(e) => handleOptionInputChange(index, optionIndex, e)}
                                        disabled={(question?.answerOptions?.length! - 1) === optionIndex}
                                        className='w-1/4 border-superbill-soap'
                                      />
                                      {(question?.answerOptions?.length! - 1) !== optionIndex ?
                                        <div className='flex items-center' onClick={() => { deleteMultipleChoice(question, optionIndex) }}>
                                          <XIcon className="cursor-pointer block h-6 w-6 fill-superpay-light-text-gray/50 hover:fill-superbill-slate-grey mb-2" aria-hidden="true" />
                                        </div>
                                        : null}
                                      {(question?.answerOptions?.length! - 1) === optionIndex ?
                                        <div className='flex items-center' onClick={() => { addMultipleChoice(question) }}>
                                          <PlusIcon className="cursor-pointer block h-6 w-6 child:fill-superpay-light-text-gray/50 hover:child:fill-superbill-slate-grey mb-2" aria-hidden="true" />
                                        </div>
                                        : null}
                                    </div>)}
                                </div>
                              </div>
                              : null} */}
                            <Subquestions
                              handleSubquestionsBackgroundContextAndResponseInstructions={handleSubquestionsBackgroundContextAndResponseInstructions}
                              handleBackgroundInfoModalState={handleBackgroundInfoModalState}
                              handleResponseInstructionsModalState={handleResponseInstructionsModalState}
                              addSubQuestionForMC={addSubQuestionForMC}
                              setValue={setValue}
                              handleOptionInputChange={handleOptionInputChange}
                              allowedString={scriptData?.inputs ?? []}
                              register={register}
                              control={control}
                              index={index}
                              question={question}
                              addSubQuestion={addSubQuestion}
                              addMultipleChoice={addMultipleChoice}
                              deleteMultipleChoice={deleteMultipleChoice}
                              addSubQuestionToFollowUps={addSubQuestionToFollowUps}
                              handleSubQuestionInputChange={handleSubQuestionInputChange}
                              handleSubQuestionSelectChange={handleSubQuestionSelectChange}
                              dragSubIndex={dragSubIndex}
                              handleDragOver={handleDragOver}
                              handleDragSubStart={handleDragSubStart}
                              handleDragSubEnter={handleDragSubEnter}
                              handleDragSubLeave={handleDragSubLeave}
                              handleDropSubquestion={handleDropSubquestion}
                              deleteQ={handleDeleteSubQuestion}
                              duplicate={handleDuplicateSubQuestion}
                              handleSubQuestionBackgroundChange={handleSubQuestionBackgroundChange}
                              handleSubQuestionResponseChange={handleSubQuestionResponseChange}
                            />
                          </div>
                        </div>

                      </>
                      : null}
                  </>

                )}
              </ul>
            </form>
            : null}
          {scriptData?.pms && showPmsForm ?
            <div className="flex items-center justify-between w-full">
              {/* <div className="font-bold text-sm font-jakarta text-superbill-jacarta">Add any additional custom questions</div>
          <button type="button" className='bg-transparent font-jakarta hover:bg-superbill-lavender-dark/50 text-superbill-jacarta font-semibold py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300' onClick={addQuestion}>
            Add Question
          </button> */}
            </div>
            :
            <button type="button" className='bg-transparent font-jakarta hover:bg-superbill-lavender-dark/50 text-superbill-jacarta font-semibold py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300' onClick={addQuestion}>
              Add Question
            </button>
          }

          <div className="h-48"></div>
        </div>
        {rightPanelOpen ?
          <div className="w-[350px] bg-white h-screen border-l sticky right-0 top-0 z-40">
            <div className="flex flex-col h-full divide-y ">
              <div className="flex justify-between items-center">
                <nav className='font-jakarta text-superbill-jacarta font-bold text-lg px-6 py-4 w-full'>
                  Help
                </nav>
                <XIcon className="w-4 h-4 mr-4 fill-superbill-slate-grey/50 hover:fill-superbill-slate-grey cursor-pointer ease-in-out duration-150 " onClick={() => setRightPanelOpen(false)} />
              </div>
              <div className="flex-grow overflow-y-auto p-3">
                <Accordion title={'When I place calls, which version of the script will be used?'} content={<div className="font-sans text-sm text-superbill-secondary">
                  When you place a call, we will use the version of the script at the <strong>time we make the call</strong>. It could take up to 2 business days for us to make the call, so if you edit your script in that time period those changes may or may not be reflected in our calls. We do not recommend editing a script when calls are “in progress”.
                </div>} />
                <Accordion title={'What are some examples of questions?'} content={<div className="font-sans text-sm text-superbill-secondary">
                  The most successful questions are phrased as standalone, straightforward questions. For example, if you’d like to ask about coverage for specific codes, write the question as “Is there coverage for D-4346?” or “Does the plan cover code D-4346?” We would not recommend writing questions with multiple components, such as “What percentage is covered and does it apply to the deductible?”
                </div>} />
                <Accordion title={'What are the different question types?'} content={<div className="font-sans text-sm text-superbill-secondary">
                  The most successful questions are phrased as standalone, straightforward questions. For example, if you’d like to ask about coverage for specific codes, write the question as “Is there coverage for D-4346?” or “Does the plan cover code D-4346?” We would not recommend writing questions with multiple components, such as “What percentage is covered and does it apply to the deductible?”
                  <ul className="ml-4 list-disc">
                    <li className="text-sm"><span className="font-bold">Yes/No:</span> Use yes/no type for any boolean question (typically starts with "Does.." or "Is.." Boolean questions allow users to add follow-up questions based on the response ("if the answer is YES" or "if the answer is NO")</li>
                    <li className="text-sm"><span className="font-bold">Text:</span> Use text type for questions that expect sentences or lists as an answer. For example, questions like "What is the process for applying to this group?" or "What codes do you support?" would work well with the text type. </li>
                    <li className="text-sm"><span className="font-bold">Number:</span> Use number type for questions that expect ONLY numbers in its answer and don't require special formatting. For example, questions like "What is the visit limit" and "What is the co-insurance percentage" would work for number type, but "What is the phone number for verifying benefits" would work better with text type due to formatting </li>
                    <li className="text-sm"><span className="font-bold">Alphanumeric:</span> Use alphanumeric type for questions that expect a single string of alphanumeric characters, such as an ID or reference number. </li>
                    <li className="text-sm"><span className="font-bold">Dollar:</span> Use dollar type for questions whose answer should be formatted as a currency amount, such as "What is the co-pay" or "How much of the deductible is remaining" </li>
                    <li className="text-sm"><span className="font-bold">Date:</span> Use date type for questions asking about dates, such as "What is the start date of the benefit period" </li>
                    <li className="text-sm"><span className="font-bold">Address:</span> Use address type for questions asking for an address, such as "What is the address listed on file for this provider?" </li>
                    <li className="text-sm"><span className="font-bold">Phone Number:</span> Use the phone number type for questions asking for a phone number, such as "What number should I call for claim status updates?" </li>
                    <li className="text-sm"><span className="font-bold">Fax Number:</span> Use the fax number type for questions asking for a fax number, such as "To which fax number was the information sent to?" </li>
                    {/* <li className="text-sm"><span className="font-bold">Multiple Choice:</span> Use multiple choice type when the answer is expected to be one of a specific list of items. Expected answers must be defined in the "multiple choice options" list. An example of a multiple choice question would be "What is the plan type" with answer options "HMO", "PPO", "EPO" </li> */}
                  </ul>
                </div>} />
                <Accordion title={'How do I re-order questions?'} content={<div className="font-sans text-sm text-superbill-secondary">
                  To reorder questions, drag the purple sliver on the left side of the question, and place it where you’d like the question to go.
                </div>} />
                <Accordion title={'How do I duplicate questions?'} content={<div className="font-sans text-sm text-superbill-secondary">
                  To duplicate a question, click the kebab menu (vertical 3 dots) and select “Duplicate” from the dropdown.
                </div>} />
                <Accordion title={'Can I reference input data in my script?'} content={<div className="font-sans text-sm text-superbill-secondary">
                  Yes! Any required inputs can be referenced in the script by using curly braces &#123; &#125;. For example, you could mention the Claim Charge Amount by typing &#123;claimChargeAmount&#125;. <br /> The inputs available depend on your selected script type. For this script, you may use the following inputs:
                  <ul className="ml-4 list-disc">
                    {Object.keys(inputs).map((key) => {
                      if (inputs[key].required && inputs[key].required === true && key) {
                        return (
                          <li className="text-sm">{key}</li>
                        );
                      }
                    })}
                  </ul>
                  If you type an input in correctly, it will be highlighted in blue:
                  <div className="bg-superbill-lavender rounded p-1 text-superbill-indigo w-fit">&#123;claimChargeAmount&#125;</div>

                  If you there are any typos, or you use an unavailable input, it will be highlighted in red:
                  <div className="bg-superbill-banner-red rounded p-1 text-superbill-ultra-red w-fit">&#123;claimchargeamount&#125;</div>
                </div>} />

              </div>
              <div className="flex flex-row justify-center px-4 py-3 sticky bottom-0 bg-white">
                <div onClick={() => { setCalendlyModalIsOpen(true); }}
                  className='bg-transparent font-jakarta hover:bg-superbill-lavender-dark/50 text-superbill-jacarta font-semibold py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300 cursor-pointer'
                >
                  Contact Support
                </div>

              </div>
            </div>
          </div>
          : <></>}
      </div>
      <div className='flex-[5]'>
        {!rightPanelOpen ? (
          <div
            onClick={() => setRightPanelOpen(true)}
            className='font-jakarta text-sm font-semibold text-superbill-jacarta flex items-center cursor-pointer rounded-full hover:bg-superbill-anti-flash-white py-2 px-3 w-fit h-fit bg-white fixed border border-superbill-soap border-b-0.5 bottom-5 right-10 drop-shadow ease-in-out duration-150 '>
            <QuestionIcon className='' />
          </div>
        ) : null}
      </div>
      <CalendlyModal isOpen={calendlyModalIsOpen} setIsOpen={setCalendlyModalIsOpen} />
      <BackgroundInfoModal selectedIndex={selectedIndex} isOpen={showBackgroundModal} onClose={() => { setShowBackgroundModal(false);}} onSubmit={(index, data) => {
        // band-aid to reuse regina's code: number == question | string == followup
        if(typeof index === 'number'){
          console.log(index, data, 'backgroundContext');
          handleBackgroundContextAndResponseInstructions(index, data, 'backgroundContext')
        }else{
          handleSubquestionsBackgroundContextAndResponseInstructions(index, data, 'backgroundContext')
          handleBackgroundInfoModalState(null, false)
        }
      }} />
      <ResponseInstructionsModal selectedIndex={selectedIndex} isOpen={showResponseModal} onClose={() => { setShowResponseModal(false);}} onSubmit={(index, data) => {
        // band-aid to reuse regina's code: number == question | string == followup
        if(typeof index === 'number'){
          handleBackgroundContextAndResponseInstructions(index, data, 'responseInstruction')
        }else{
          handleSubquestionsBackgroundContextAndResponseInstructions(index, data, 'responseInstruction')
          handleResponseInstructionsModalState(null, false)
        }
      }} />

    </div>
  );
});

export default QuestionPage;
