import React, { useEffect, useState } from 'react';
import Loader from 'components/Loader';
import { ReactComponent as InfoIcon } from "sign-up/superpay/assets/info.svg";
import UploadCSVComponent from './components/upload-csv-component';
import { getScriptInputs, runScript, getWebhookData } from 'apis/robodialer';
import toast from 'react-hot-toast';


type UploadCSVPageProps = {
  onClose: () => void;
  onSuccess: () => void;
  onNext: () => void;
  onError: (errors) => void;
  csvFile: any;
  setCsvFile: React.Dispatch<React.SetStateAction<any>>;
  selectedScript: string | null;
  inputs: any | null;
  setInputs: React.Dispatch<React.SetStateAction<any>>;
  csvString: string;
  maxRows: number;
  setCsvString: React.Dispatch<React.SetStateAction<string>>;
  selectedScriptType: string | null;
}

const UploadCSVPage = ({ onClose, onNext, csvFile, maxRows = 500, setCsvFile, selectedScript, selectedScriptType, onSuccess, onError, csvString, setCsvString, inputs, setInputs }: UploadCSVPageProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [invalidFile, setInvalidFile] = useState<boolean>(false);
  
  const [headersError, setHeadersError] = useState<boolean>(false);
  const [headersErrorMsg, setHeadersErrorMsg] = useState<string | null>(null);
  
  const [fetchingInputs, setFetchingInputs] = useState<boolean>(false);
  const [statusIsLive, setStatusIsLive] = useState<boolean>(true);
  const [showOptionalInputs, setShowOptionalInputs] = useState<boolean>(false);



  const generateCSV = (e) => {
    if (e && e.preventDefault) {
      e?.preventDefault()
    }
    const headers = Object.keys(inputs);
    const dummyData = headers.map(() => "exampleCsv");

    const csvContent = `${headers.join(",")}\n${dummyData.join(",")}`;

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "data.csv");
    link.click();
  };

  const replaceHeadersInCSV = (headersToReplace) => {
    let updatedCSVString = csvString;
    for (const header in headersToReplace) {
      const value = headersToReplace[header].value;
      const regex = new RegExp(`\\b${value}\\b`, 'g');
      updatedCSVString = updatedCSVString.replace(regex, header);
    }
    
    setCsvString(updatedCSVString)
    return updatedCSVString;
  };

  const checkHeader = async () :Promise<string | "missing" | "error" | "rows_error" | "max_rows_error"> => {
    setInvalidFile(false)
    setHeadersError(false);
    if (csvString && inputs) {

      // Step 1: Split the CSV string into lines
      const lines = csvString.split('\n');
      if(lines.length < 2){
        return "rows_error"
      }
      /* if(maxRows+1 < lines.length){
        return "max_rows_error"
      } */
      
      // Step 2: Get the CSV headers
      const csvHeaders = lines[0].split(',');
      // Step 3: Get the CSV data
      /* const csvData = lines.slice(1).map(line => {
        const values = line.split(',');
        const obj = {};
        // Assign values to corresponding properties
        csvHeaders.forEach((header, index) => {
          obj[header] = values[index];
        });
        return obj;
      }); */
      // empty header
      if(csvHeaders.includes('')){
        return 'error'
      }
      // Step 4: Get the length of the headers
      const csvHeadersLength = csvHeaders.length;
      // Step 5: Get the required headers from the object
      const requiredHeaders = Object.keys(inputs).filter(
        (header) => inputs[header].required === true
      );

      const optionalHeaders = Object.keys(inputs).filter(
        (header) => inputs[header].required === false
      );
      // Step 6: Check if the lengths match
      const countsMatch = requiredHeaders.length <= csvHeadersLength;
      // Check if all required headers are present in the CSV
      let toReplace = {}
      const allHeadersPresent = requiredHeaders.every((header) =>{
        let skip = false
        if (csvHeaders.includes(header)) {
        }else{
          if(inputs[header]?.['input-alias']){
            
            for (const iterator of inputs[header]?.['input-alias']) {
              if (csvHeaders.includes(iterator)) {
                skip = true
                toReplace[header] = {value: iterator}
              }
            }
            
            //const index = inputs[header]?.['input-alias'].indexOf(header);
          }

        }
        
        return csvHeaders.includes(header) || skip
      });

      optionalHeaders.map((header) =>{
        if (!csvHeaders.includes(header)){
          if(inputs[header]?.['input-alias']){
            for (const iterator of inputs[header]?.['input-alias']) {
              if (csvHeaders.includes(iterator)) {
                toReplace[header] = {value: iterator}
              }
            }
          }
        }
        
      });
      const updatedCSVString = replaceHeadersInCSV(toReplace)
      /* const extraHeaders = csvHeaders.filter(
        (header) => !requiredHeaders.includes(header)
      );
      
      const missingHeaders = requiredHeaders.filter(
        (header) => !csvHeaders.includes(header)
      ); */
      const wait2Seconds = () => new Promise(resolve => setTimeout(resolve, 2000));
      wait2Seconds()
      if (countsMatch && allHeadersPresent) {
        // The headers match
        return updatedCSVString
      } else if (countsMatch) {
        // Some required headers are missing
        return 'missing'
      } else {
        // The header counts do not match
        // error here
        return 'missing'
      }

    }
    return 'error'
  }

  const submitCsv = async () => {
    let n_csvString = await checkHeader();
    if( n_csvString === 'error'){
      setHeadersErrorMsg('Invalid File, header do not match')
      setHeadersError(true);
      return
    }else if( n_csvString === 'missing'){
      onNext();
      return
    }else if( n_csvString === 'rows_error'){
      setInvalidFile(true)
      return
    }else if( n_csvString === 'max_rows_error'){
      setInvalidFile(true)
      return
    }
    setIsLoading(true)
      
    const updatedCsvBlob = new Blob([n_csvString], { type: 'text/csv' });
    const updatedCsvFile = new File([updatedCsvBlob], csvFile.name, { type: 'text/csv' });
    let { httpCode, data } = await runScript(updatedCsvFile, selectedScript, csvFile.name)
    setIsLoading(false)
    if (httpCode === 400) {
      onError(data.errors)
    } else if (httpCode !== 200) {
      toast.error('Unable to upload this file', {
        iconTheme: {
          primary: '#F36F82',
          secondary: '#ffffff',
        },
      });
    } else {
      onSuccess()
    }
  }
  useEffect(() => {
    const fetch = async () => {
      setFetchingInputs(true)
      const webhookData = await getWebhookData()
      if (webhookData.data.status) {
        setStatusIsLive(webhookData.data.status==='live');
      } else {
        setStatusIsLive(true)
      }
      const { httpCode, data } = await getScriptInputs(selectedScriptType)
      if (httpCode === 200) {
        setFetchingInputs(false)
        setInputs(data)
      }
    }
    if(selectedScriptType){
      fetch();
    }
    /* if (["authorization-approval-notification","prior-auth-status","prior-auth-request", "prescription-status","claim-status", "enrollment-application-status", "vob", 'other', "provider-data", "network-status","enrollment-application-submission", "hipaa-right-of-access-request"].includes(selectedScriptType!)) {
    } else {
      setInputs({})
    } */
    setHeadersError(false);

  }, [selectedScriptType])

  useEffect(() => {
    setHeadersErrorMsg('')
    setHeadersError(false);
    if (csvFile) {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target && event.target.result) {
          const csvString = event.target.result as string;    
          const lines = csvString.split('\n').filter((line) => line.trim() !== '');
          if(lines.length < 2){
            setHeadersErrorMsg('Invalid File')
            return
          }
          /* if(maxRows+1 < lines.length){
            setHeadersErrorMsg(`CSV file cannot contain more than ${maxRows} rows. If you'd like to place more calls, upload another CSV file`)
            return
          } */
          const trimmedLines = lines.map(function(line) {
            return line.trim();
          });
          // Join the trimmed lines with '\n'
          const resultString = trimmedLines.join('\n');
          setCsvString(resultString);
        }
      };
      reader.readAsText(csvFile);
    } else {
      setCsvString('');
    }
  }, [csvFile])

  return (
    <div className="flex flex-col h-full divide-y">
      {/* Navbar */}
      <nav className="font-jakarta font-bold px-6 pt-7 pb-4 text-xl text-superbill-jacarta">
        Run a Script
      </nav>
      <div className="flex-grow overflow-y-auto p-9">
        {/* <div className='flex justify-start font-sans items-center w-full h-fit bg-superbill-ghost-white p-2 rounded mt-1 mb-2'>
          <div className='flex justify-start'>
            <InfoIcon className='min-w-[22px]' />
          </div>
          <div className='ml-2 text-superbill-jacarta text-left text-sm sm:flex-[90] '>
            Need an example CSV file? <span className='hover-underline-animation cursor-pointer'>Download our template</span>
          </div>
        </div> */}
        {statusIsLive?
        null
          :
        <div className='flex justify-between items-center w-full h-fit rounded bg-superbill-lavender p-2 mb-2'>
          <div className="flex justify-start items-center">
            {/* <div className='flex justify-start pl-2 items-center'>
              <InfoIcon className='min-w-[22px]' />
            </div> */}
            <div className='bg-superbill-indigo py-1 px-2 text-white rounded mr-1 text-center text-xs'><strong>Demo Portal</strong></div>
            <div className='ml-2 text-superbill-jacarta text-left text-sm sm:flex-[90] '>
              This is a preview of the CSV upload. Placing calls is only available to live customers.
            </div>
          </div>
        </div>
          }
        <div className='flex justify-between items-center w-full h-fit rounded bg-superbill-ghost-white p-2'>
          <div className="flex justify-start items-center">
            <div className='flex justify-start pl-2 items-center'>
              <InfoIcon className='min-w-[22px]' />
            </div>
            <div className='ml-2 text-superbill-jacarta text-left text-sm sm:flex-[90] '>
              Need an example CSV file?
              <button type='button' className="ml-2 text-superbill-ultramarine text-sm font-sans font-bold hover-underline-animation cursor-pointer" onClick={generateCSV}>
                Download our template
              </button>
            </div>
          </div>
        </div>
        <div className='grid grid-cols-1 lg:grid-cols-2 gap-x-4 mt-5'>
          <div className='rounded bg-superbill-ghost-white p-2 mb-3 overflow-auto'>
            <div className='mt-3'>
              {Object.keys(inputs).length || fetchingInputs ?
                <div className='w-full h-fit rounded bg-superbill-ghost-white px-4 pb-4'>
                  {!fetchingInputs ?
                    <>
                      <div className='text-superbill-jacarta text-sm'>
                        <div> <strong>Your CSV file must contain the following column headers.</strong></div>
                        <div> Don’t worry if their names are slightly different - you can match them up later</div>
                      </div>
                      <div className="justify-between mt-3">
                        <div className="">
                          <div className="text-superbill-jacarta text-sm font-bold mb-2">Required</div>
                        <div className="bg-superbill-anti-flash-white p-2 border border-superbill-soap rounded">
                          <ul className="list-none grid grid-cols-2 p-0 w-full">
                            {Object.keys(inputs).map((key, index) => {
                              if (inputs[key].required && inputs[key].required === true && key) {
                                return (
                                  <li key={`req-${key}-${index}`} className="text-superbill-jacarta text-sm w-full truncate hover:text-clip">{key}</li>
                                );
                              }
                            })}
                            {!Object.keys(inputs).filter((key) => inputs[key].required && inputs[key].required === true).length && <p className="text-superbill-jacarta text-sm">No required inputs</p>}
                          </ul>
                          </div>
                        </div>
                        <div className="mt-4">
                          {showOptionalInputs ?
                          <>
                          <button type='button' className="text-superbill-ultramarine text-sm font-sans font-bold hover-underline-animation cursor-pointer mb-2"  onClick={() => setShowOptionalInputs(false)}>
                          Hide Optional Inputs
                        </button>
                        <div className="bg-superbill-anti-flash-white p-2 border border-superbill-soap rounded">
                        <ul className="list-none grid grid-cols-2 p-0 w-full">
                            {Object.keys(inputs).map((key,index) => {
                              if (!inputs[key].required && key) {
                                return (
                                  <li key={`opt-${key}-${index}`} className="text-superbill-jacarta text-sm truncate hover:text-clip">{key}</li>
                                );
                              }
                            })}
                          </ul>
                          </div>
                        </>
                        :
                        <>
                        <button type='button' className="text-superbill-ultramarine text-sm font-sans font-bold hover-underline-animation cursor-pointer mb-2"  onClick={() => setShowOptionalInputs(true)}>
                          Show Optional Inputs
                        </button>
                        </>
                        }
                        </div>
                      </div>
                    </>
                    : <div className='w-full h-full flex justify-center items-center'>
                      <Loader />
                    </div>}

                </div>
                : null}
            </div>
          </div>
          <div className='m-auto w-full h-full'>
            {statusIsLive ?
            <UploadCSVComponent maxRows={maxRows} setCsvFile={setCsvFile} csvFile={csvFile} />
            :
            <>
              <div className="border border-dashed border-superbill-soap p-4 rounded text-center">
                <div className="p-4">
                    <div className='flex flex-col justify-center items-center'>
                      <div className='my-1 text-base text-superpay-light-text-gray'>Placing calls is only available to live customers 
                      </div>
                    </div>
                    <button
                      className='bg-superbill-anti-flash-white font-jakarta text-superbill-jacarta/50 font-semibold py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300 mt-5' disabled={true}
                    >
                      Upload
                    </button>
                  </div>
                <div className="flex items-center mb-4">
                </div>
              </div>
            </>
          }
          </div>
        </div>
      </div>
      {/* Toolbar */}
        {headersError ? 
          <div className='flex justify-between items-center w-full h-fit rounded bg-superbill-banner-red p-2 my-3 '>
              <div className="flex justify-start items-center">
                <div className='flex justify-start pl-2 items-center'>
                  <InfoIcon className='child:fill-superbill-ultra-red-hover min-w-[22px]' />
                </div>
                <div className='ml-2 text-superbill-jacarta text-left text-sm sm:flex-[90] '>
                  {headersErrorMsg ?? 'Invalid CSV, header do not match'}
                </div>
              </div>
            </div>
        :null}
      <div className="flex flex-row justify-between px-4 py-3">
        <button
          className='bg-transparent font-jakarta hover:bg-superbill-lavender-dark/50 text-superbill-jacarta font-semibold py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300'
          onClick={onClose}
        >
          Close
        </button>
        <div className='flex items-center'>
        {statusIsLive?
        null
        :
        <div className='text-xs text-superbill-slate-grey mr-2 font-sans'>
          Placing calls is only available to live customers. Contact support@superdial.ai to enable calls.
          </div>
        }
        <button
          disabled={!csvFile || !selectedScript || isLoading || invalidFile || !statusIsLive}
          className='bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300'
          onClick={submitCsv}
        >
          {isLoading ? <Loader size={42} /> : <>Place Calls</>}
        </button>
        </div>
      </div>
    </div>
  );
};

export default UploadCSVPage;
